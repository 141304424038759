const enumState = [
  { id: 10, value: 10, title: "Em análise" },
  { id: 20, value: 20, title: "Aprovado" },
  {
    id: 25,
    value: 25,
    title: "Aprovado com moderação",
  },
  { id: 30, value: 30, title: "Recusado" },
];
export default enumState;
