import styled from "styled-components";

export const Wrapper = styled.div`
  #brand {
    background: none;
    box-shadow: none;
    img {
      margin-left: 10px;
      height: 35px;
    }
  }

  .imgProfile {
    height: 35px;
  }
`;
