import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import api from "~/services/api";
import URLApi from "~/config/URLApi";

import history from "~/services/history";

import { toast } from "react-toastify";

import euapoio1 from "~/assets/euapoio1new.jpg";
import euapoio2 from "~/assets/euapoio2.jpg";

import CardStudentPage from "~/components/CardStudentPage";

import { Wrapper } from "./styles";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import PaginationComponent from "react-js-pagination";

import { FaRegCalendarTimes } from "react-icons/fa";
import { MdOpenInNew, MdAccessTime, MdInfoOutline } from "react-icons/md";
import {
  Carousel,
  Container,
  Row,
  Col,
  Card,
  Button,
  Spinner,
  Pagination,
  Dropdown,
  Image,
  Modal
} from "react-bootstrap";

import banner from "../../assets/Banner.jpg"

export default function AllDonationsPage() {
  const [donations, setDonations] = useState([]);

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const [activePage, setActivePage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(0);

  const [modal, setModal] = useState(false);

  useEffect(() => {
    async function onInit() {
      await loadData(activePage);
    }
    onInit();
  }, [activePage]);

  async function loadData(item) {
    setLoading(true);
    setDonations([]);
    try {
      setMessage("Carregando...");
      const response = await api.get(
        `/public/getcampaigncards/${item}/${itemPerPage}/4`
      );



      if (response.status == 200) {
        const numCaracter = 120;
        const data = response.data;
        setTotalItens(data.indexSize);

        if (data.entities.length > 0) {
          setDonations(
            data.entities.map((x) => ({
              campaignId: x.campaignId,
              name: x.name,
              description: x.description,
              startDate: x.startDate
                ? format(new Date(x.startDate), "dd 'de' MMM 'de' yyyy", {
                  locale: pt,
                })
                : null,
              endDate: x.endDate
                ? format(
                  new Date(x.endDate),
                  "dd/MM/yyyy HH:mm",
                  "dd 'de' MMM 'de' yyyy HH:mm",
                  {
                    locale: pt,
                  }
                )
                : null,
              donationPageCards: x.donationPageCards.map((y) => ({
                ...y,
                textSplit: `${y.pageText.substring(0, numCaracter)} ${y.pageText.length > numCaracter ? "..." : ""
                  }`,
                urlImage: !!y.imageId ? `${URLApi}/image/${y.imageId}` : null,
              })),
            }))
          );

          setMessage("");
          setLoading(false);
        } else {
          setMessage("Nenhum item para renderizar");
        }
      } else {
        toast.error("Ocorreu um erro ao carregar os itens.. tente novamente");
      }
    } catch (error) {
      setMessage("Erro ao carregar a página.. tente novamente");
    }
  }



  return (
    <Wrapper>
      <div>
        <Image src={banner} id="bannerImg" fluid ></Image>
        <Button id="bannerBtn" onClick={() => setModal(true)} >Doe Agora</Button>
      </div>

      {/* <Carousel>
        <Carousel.Item>
          <div className="d-flex justify-content-center">
            <img className="d-block w-100" src={euapoio1} alt="First slide" />
          </div>
          <Carousel.Caption>
            <h3>Faça parte desta corrente pela educação</h3>
            <h5>e ajude a realizar os sonhos dos nossos alunos!!</h5>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <div className="d-flex justify-content-center">
            <img className="d-block w-100" src={euapoio2} alt="Third slide" />
          </div>
          <Carousel.Caption>
            <h3>Suas doações podem chegar até onde suas mãos não podem.</h3>
            <h5>Seja solidário e contribua!!</h5>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel> */}

      {loading ? (
        <Container>
          <div className="contentcenter mt-5">
            {message == "Carregando..." && <Spinner animation="border" />}{" "}
            <h4>{message}</h4>
          </div>
        </Container>
      ) : (
        <Container>
          <Row className="d-flex justify-content-center">
            <Col xs={12} sm={10} className="title">
              <h1 className=" my-5 text-center">
                Muitos estudantes precisam de você. Nos ajude a diminuir os
                impactos desta pandemia!!
              </h1>
            </Col>
          </Row>
          {donations.map((x, ind) => (
            console.log(donations),
            <Row
              key={ind}
              className=" my-4 donations d-flex justify-content-center"
            >
              <Col xs={10} sm={12}>
                <div className="row bg-light no-gutters border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                  <div className="col p-4 d-flex flex-column position-static">
                    <h3 className="mb-2">
                      {
                        x.name === "#EuApoio - Campus SP" ?
                          "#EuApoio - Campus São Paulo"
                          : x.name === "#EuApoio - Campus EC" ?
                            "#EuApoio - Campus Engenheiro Coelho"
                            : x.name === "#EuApoio - Campus HT" ?
                              "#EuApoio - Campus Hortolândia"
                              : x.name === "#EuApoio - Campus EAD" ?
                                "#EuApoio - Campus EAD"
                                : null
                      }
                    </h3>
                    <Row>
                      <Col sm={3}>
                        <div className="mb-1 text-muted">
                          {x.startDate && (
                            <>
                              <MdAccessTime size={14} className="mr-1" />{" "}
                              Início: {x.startDate}
                            </>
                          )}
                        </div>
                        <div className="mb-3 text-muted">
                          {x.endDate && (
                            <>
                              <FaRegCalendarTimes size={14} className="mr-1" />{" "}
                              Fim: {x.endDate}
                            </>
                          )}
                        </div>
                      </Col>
                      <Col sm={9} className="border-left border-info">
                        <p className="card-text mb-auto">
                          <MdInfoOutline
                            size={14}
                            className="mr-1"
                            style={{ marginTop: "-2px" }}
                          />
                          {x.description}
                        </p>
                      </Col>
                    </Row>

                    <div className="d-flex justify-content-end">
                      <Link
                        to={`/campaign/${x.campaignId}`}
                        className="mt-3 btn btn-info"
                        id="bodyBtn"
                      >
                        Pesquise um aluno para doar <MdOpenInNew />
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              {x.donationPageCards.map((x, index) => (
                <CardStudentPage key={index} data={x} />
              ))}
              <Col xs={12}>
                <hr />
              </Col>
            </Row>
          ))}
        </Container>
      )}
      <Container
        hidden={message.toLowerCase().trim() === "carregando..." ? true : false}
        className="mt-5"
      >
        <Row
          className={`justify-content-center ${totalItens <= itemPerPage ? "d-none" : "d-flex"
            }`}
        >
          <Col xs={10} sm={5} className="d-flex justify-content-center">
            <PaginationComponent
              itemClass="page-item"
              linkClass="page-link"
              activePage={activePage}
              itemsCountPerPage={itemPerPage}
              totalItemsCount={totalItens}
              pageRangeDisplayed={5}
              onChange={(e) => setActivePage(e)}
            />
          </Col>
        </Row>
      </Container>



      {/* Modal Doe Agora */}
      <Modal show={modal} onHide={() => setModal(false)} animation={false} centered size="lg">
        <Modal.Header closeButton >
          <h3><strong>Escolha o Campus</strong></h3>
        </Modal.Header>
        <Modal.Body>
          <Row className="px-3">
            {
              donations.map((x, i) =>
                <Button className="col py-4 mx-1 modalBtn" key={i} onClick={() => history.push(`/campaign/${x.campaignId}`)} >
                  {
                    x.name === "#EuApoio - Campus SP" ?
                      "São Paulo"
                      : x.name === "#EuApoio - Campus EAD" ?
                        "EAD"
                        : x.name === "#EuApoio - Campus EC" ?
                          "Engenheiro Coelho"
                          : x.name === "#EuApoio - Campus HT" ?
                            "Hortolândia"
                            : null
                  }
                </Button>
              )
            }
          </Row>
        </Modal.Body>
      </Modal>
    </Wrapper>

  );
}
