import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import Cards from "react-credit-cards";
import { toast } from "react-toastify";

import HCaptcha from "@hcaptcha/react-hcaptcha";

import { formatPrice } from "~/util/format";

import { format, addMonths, addDays } from "date-fns";

import { Form, Input, Select } from "@rocketseat/unform";

import { MdCheckCircle } from "react-icons/md";

import { Wrapper } from "./styles";
import {
  Container,
  Row,
  Col,
  Alert,
  Button,
  InputGroup,
  Spinner,
  Modal,
  Card,
  Badge,
} from "react-bootstrap";
import InputMask from "react-input-mask";

import Switch from "react-switch";

import api from "~/services/api";

export default function CartaoCC({ idclient, data, routePay, setInvoice }) {
  const { profile, template } = useSelector((state) => state.user);

  const [donorName, setDonorName] = useState("");
  const [donorMail, setDonorMail] = useState("");

  const [cvc, setCvc] = useState("");
  const [expiry, setExpiry] = useState("");
  const [focus, setFocus] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");

  const [price, setPrice] = useState("");
  const [customPrice, setCustomPrice] = useState("");
  const [typeDonation, setTypeDonation] = useState("");
  const [installments, setInstallments] = useState(1);

  const [identificacao, setIdentificacao] = useState(false);
  const [loading, setLoading] = useState(false);

  const [paymentDone, setPaymentDone] = useState(false);
  const [paymentObj, setPaymentObj] = useState({});

  const [authPeople, setAuthPeople] = useState(false);

  const [modalTermUse, setModalTermUse] = useState(false);
  const [acceptTerm, setAcceptTerm] = useState(false);
  const [acceptted, setAcceptted] = useState(false);
  const [termoDeUso, setTermoDeUso] = useState("");

  const captcha = useRef(null);
  const [userValid, setUserValid] = useState(true);

  useEffect(() => {
    if (profile !== null && template === "donor") {
      setDonorName(profile.name);
      setDonorMail(profile.email);
      setAuthPeople(true);

      if (profile.regulationAccepted) {
        setAcceptTerm(true);
        setAcceptted(true);
      }
    }

    setTermoDeUso(data.giverRegulation);
  }, []);

  useEffect(() => {
    setPrice(customPrice !== "outro" ? customPrice : "");
  }, [customPrice]);

  useEffect(() => {
    setInstallments(typeDonation === "unica" ? "1" : "");
  }, [typeDonation]);

  async function handlePay(e) {
    var location = "";
    try {
      const response = await api.get(`https://api.ipgeolocation.io/ipgeo?apiKey=4a000754c89a4078ac6838e11a9090f1`);
      location = JSON.stringify(response.data);
    } catch (error) {

    }

    if (!userValid) {
      toast.error("Por favor, aceite o captcha");
      return;
    }

    if (!acceptTerm) {
      toast.error("Você precisa aceitar os termos de uso");
      return;
    }

    if (price.trim() === "" || price.trim() === "0") {
      toast.error("O valor deve ser maior que zero");
      return;
    }

    if (!userValid) {
      toast.error("Por favor, aceite o captcha");
      return;
    }

    let dateExpiry = expiry.trim().split("/");

    dateExpiry[1] =
      Number(dateExpiry[1]) < 2000
        ? Number(dateExpiry[1]) + 2000
        : Number(dateExpiry[1]);

    const newDateExpiry = dateExpiry.join("/");

    const jsonData = {
      Location: location,
      DonationPageId: idclient,
      GiverName: donorName,
      GiverEmail: donorMail,
      Value: Number(price),
      IsRecurrence: typeDonation === "mensal" ? true : false,
      RecurrenceTimes: installments,
      AnonymousDonator: identificacao,
      PaymentMethodCode: 50,
      PaymentParameters: JSON.stringify({
        isSignature: typeDonation === "mensal" ? true : false,
        signatureInterval: typeDonation === "mensal" ? "Monthly" : "",
        signatureEndDate:
          typeDonation === "mensal"
            ? format(
              addDays(addMonths(Date.now(), installments - 1), 1),
              "yyyy-MM-dd"
            )
            : "",
        card_number: number.trim().replace(/\s/g, ""),
        full_name: name.trim(),
        validate: newDateExpiry,
        secure_code: cvc.trim(),
        recurrent: false,
        installment: 1,
        country: "BRA"
      }),
    };

    try {
      setLoading(true);

      const response = await api.post(`${routePay}`, jsonData);
      const dataResponse = response.data;

      if (response.status === 200) {
        dataResponse.status
          ? emitCallBack({ status: true, entity: dataResponse.entity })
          : error(dataResponse.message);
      } else {
        error(dataResponse.message);
      }
    } catch (error) {
      toast.error("Ocorreu um erro, tente novamente");
    } finally {
      setLoading(false);
    }
  }

  function emitCallBack(d) {
    if (d.status) {
      setPaymentDone(true);
      setPaymentObj(d.entity);
    }
  }

  function error(message) {
    toast.error(`Ocorreu um erro com a financeira. ${!!message && message}`);
  }

  const onChange = async () => {
    const token = await captcha.current.executeAsync();
    if (captcha.current.getValue()) {
      setUserValid(true);
    }
  }

  const onExpired = () => {
    setUserValid(false);
  }

  const onVerifyCaptcha = (token) => {
    setUserValid(true);
  }

  return (
    <Wrapper>
      <Container>
        {!!idclient && !!routePay ? (
          <div id="PaymentForm">
            {paymentDone ? (
              <Row className="justify-content-center">
                <Col xs={12} lg={8} className="mb-4 mr-3">
                  <div className="success-checkmark">
                    <div className="check-icon">
                      <span className="icon-line line-tip"></span>
                      <span className="icon-line line-long"></span>
                      <div className="icon-circle"></div>
                      <div className="icon-fix"></div>
                    </div>
                  </div>
                  <h4>Pagamento realizado com sucesso</h4>
                  <Alert variant="info">
                    Seu código da transação é <b>{paymentObj.donationId}</b>
                    <br />
                    Confira sua caixa e-mail(spam) para mais detalhes
                  </Alert>
                </Col>
              </Row>
            ) : (
              <Row className="justify-content-center">
                <Col xs={12} lg={4} className="mb-4 mr-3">
                  <Cards
                    cvc={cvc}
                    expiry={expiry}
                    focused={focus}
                    name={name}
                    number={number}
                  />
                </Col>
                <Col xs={12} lg={5} className="mr-3">
                  <Form
                    id="formDonation"
                    initialData={{
                      typeDonation: "unica",
                      donorNameCC: donorName,
                      donorMailCC: donorMail,
                    }}
                    onSubmit={handlePay}
                  >
                    <Row className=" justify-content-center">
                      <Col xs={12} md={8} lg={12}>
                        <InputGroup className="mb-3">
                          <Input
                            type="text"
                            name="donorNameCC"
                            className="form-control"
                            placeholder="Nome do doador"
                            disabled={authPeople}
                            required={!authPeople}
                            onChange={(e) => setDonorName(e.target.value)}
                            onFocus={(e) => setFocus(e.target.name)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={8} lg={12}>
                        <InputGroup className="mb-3">
                          <Input
                            type="email"
                            name="donorMailCC"
                            disabled={authPeople}
                            required={!authPeople}
                            className="form-control"
                            placeholder="email@email.com"
                            onChange={(e) => setDonorMail(e.target.value)}
                            onFocus={(e) => setFocus(e.target.name)}
                          />
                        </InputGroup>
                      </Col>
                      <Badge variant="secondary">
                        Obs: obrigatório para envio do comprovante da doação
                      </Badge>
                      <Col xs={12} md={8} lg={12}>
                        <hr />
                      </Col>
                      <Col xs={12} md={8} lg={12}>
                        <InputGroup className="mb-3">
                          <Input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Nome no cartão"
                            required
                            onChange={(e) => setName(e.target.value)}
                            onFocus={(e) => setFocus(e.target.name)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={8} lg={12}>
                        <InputGroup className="mb-3">
                          <InputMask
                            mask="9999 9999 9999 9999 9999"
                            maskChar=" "
                            className="form-control"
                            type="tel"
                            name="number"
                            value={number}
                            placeholder="Numero do cartão **** **** **** ****"
                            required
                            onChange={(e) => setNumber(e.target.value)}
                            onFocus={(e) => setFocus(e.target.name)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={8} lg={12}>
                        <InputGroup className="mb-3">
                          <InputMask
                            mask="9999"
                            maskChar=" "
                            type="tel"
                            className="form-control"
                            name="cvc"
                            value={cvc}
                            placeholder="CVC"
                            required
                            onChange={(e) => setCvc(e.target.value)}
                            onFocus={(e) => setFocus(e.target.name)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={8} lg={12}>
                        <InputGroup className="mb-3">
                          <InputMask
                            mask="99/9999"
                            maskChar=" "
                            type="tel"
                            name="expiry"
                            value={expiry}
                            className="form-control"
                            placeholder="Vencimento mm/aaaa"
                            required
                            onChange={(e) => setExpiry(e.target.value)}
                            onFocus={(e) => setFocus(e.target.name)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={8} lg={12}>
                        <hr />
                      </Col>
                      <Col xs={12} md={8} lg={12}>
                        <label className="switchBox">
                          <span>Doação Anônima</span>
                          <Switch
                            onChange={setIdentificacao}
                            checked={identificacao}
                          />
                        </label>
                      </Col>
                      <Col xs={12} md={8} lg={12}>
                        <InputGroup className="mb-3">
                          <Select
                            name="typeDonation"
                            required
                            placeholder="Tipo de doação"
                            className="form-control"
                            options={[
                              {
                                value: "unica",
                                id: "unica",
                                title: "Doação Única",
                              },
                              {
                                value: "mensal",
                                id: "mensal",
                                title: "Doação Mensal",
                              },
                            ]}
                            onChange={(e) => setTypeDonation(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                      {typeDonation === "mensal" && (
                        <Col xs={12} md={8} lg={12}>
                          <InputGroup className="mb-3">
                            <Select
                              name="installments"
                              required
                              placeholder="Quantas parcelas"
                              className="form-control"
                              options={[
                                { id: "2", title: "2x" },
                                { id: "3", title: "3x" },
                                { id: "4", title: "4x" },
                                { id: "5", title: "5x" },
                                { id: "6", title: "6x" },
                              ]}
                              onChange={(e) => setInstallments(e.target.value)}
                            />
                          </InputGroup>
                        </Col>
                      )}
                      <Col xs={12} md={8} lg={12}>
                        <InputGroup className="mb-3">
                          <Select
                            name="valorCC"
                            required
                            placeholder="Valor da doação"
                            className="form-control"
                            options={[
                              { id: "100", value: "100", title: "R$ 100,00" },
                              { id: "50", value: "50", title: "R$ 50,00" },
                              { id: "20", value: "20", title: "R$ 20,00" },
                              { id: "10", value: "10", title: "R$ 10,00" },
                              {
                                id: "outro",
                                value: "outro",
                                title: "Outro valor",
                              },
                            ]}
                            onChange={(e) => setCustomPrice(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                      {customPrice === "outro" && (
                        <Col xs={10} md={7} lg={10}>
                          <InputGroup className="mb-3 ml-4">
                            <InputMask
                              value={price}
                              type="number"
                              name="price"
                              className="form-control"
                              placeholder="Outro Valor:"
                              required
                              onChange={(e) => setPrice(e.target.value)}
                            />
                          </InputGroup>
                        </Col>
                      )}
                      <Col xs={12} sm={8} className="mb-1 mt-2">
                        <h5>
                          Total: {installments && `${installments}x de`}{" "}
                          {formatPrice(price)}
                        </h5>
                      </Col>
                      {!acceptted && (
                        <label className="switchBox">
                          <Switch
                            onChange={setAcceptTerm}
                            checked={acceptTerm}
                          />
                          <span>
                            Li e aceito os{" "}
                            <Button
                              variant="link"
                              className="px-0"
                              onClick={() => setModalTermUse(true)}
                            >
                              termos de uso
                            </Button>
                          </span>
                        </label>
                      )}
                      <Col xs={12} md={8} lg={12}>
                        <HCaptcha
                          sitekey="38dbdb4b-3f74-44b5-b301-5a37489e6810"
                          onVerify={onVerifyCaptcha}
                          onExpire={onExpired}
                        />
                      </Col>
                      <Col xs={12} md={8} lg={12}>
                        <Button type="submit" block disabled={loading}>
                          {loading ? (
                            <Spinner animation="border" />
                          ) : (
                            `Processar Pagamento`
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            )}
          </div>
        ) : (
          <Alert variant="danger">
            Estão faltando alguns parâmetros para renderizar este componente
          </Alert>
        )}
      </Container>

      {/* Modal Termo de Uso */}
      <Modal
        className="mt-5"
        size="lg"
        show={modalTermUse}
        onHide={() => setModalTermUse(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Termo de uso
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card bg="white" text="dark">
            <Card.Body>
              <Card.Text
                className="h6 text-justify"
                dangerouslySetInnerHTML={{
                  __html: termoDeUso,
                }}
              ></Card.Text>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </Wrapper>
  );
}
