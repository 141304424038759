import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import api from "~/services/api";
import buildURLQuery from "~/util/buildURLQuery";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";
import history from "~/services/history";
import Switch from "react-switch";

import { formatPrice } from "~/util/format";
import { Form, Input, Select } from "@rocketseat/unform";
import {
  Table,
  Container,
  Row,
  Col,
  Dropdown,
  Spinner,
  Button,
  Modal,
  Card,
  InputGroup,
  FormControl,
  ListGroup,
} from "react-bootstrap";

import { toast } from "react-toastify";

import { IoMdMenu, IoMdSearch } from "react-icons/io";
import { TiExport } from "react-icons/ti";
import { AiFillMoneyCollect } from "react-icons/ai";
import { MdClear, MdUpdate, MdAddCircleOutline } from "react-icons/md";
// import { Container } from './styles';

export default function ContasFinanceiras() {
  const tenantProfile = useSelector((state) => state.header.tenantProfile);

  const formRef = useRef(null);
  const searchRef = useRef(null);
  const [searching, setSearching] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [timeout, setTimeOut] = useState(0);

  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  const [activePage, setActivePage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(0);

  const [updatePending, setUpdatePending] = useState({});
  const [modalUpdate, setModalUpdate] = useState(false);

  const [modalCreateLot, setModalCreateLot] = useState({
    loading: false,
    sendingLoading: false,
    show: false,
    data: [],
  });

  const [modalInsertLot, setModalInsertLot] = useState({
    loading: false,
    sendingLoading: false,
    show: false,
    data: [],
  });

  // change new tenant
  useEffect(() => {
    setActivePage(1);
  }, [tenantProfile, searchString]);

  /* Load data pages */
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);

        const response = await api.get(
          `/AdminArea/GetTenantFinancialAccounts/${tenantProfile}/${activePage}/${itemPerPage}?${buildURLQuery(
            { search: searchString }
          )}`
        );

        const dataDonations = response.data;
        setTotalItens(dataDonations.indexSize);
        setPages(dataDonations.entities.map((x) => x));
      } catch (error) {
        if (!!error.response && error.response.status !== 401) {
          toast.error("Erro ao carregar a página");
        }
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [activePage, setItemPerPage, tenantProfile, searchString]);

  useEffect(() => {
    if (searching) {
      searchRef.current.focus();
    }
  }, [searching]);

  const handleViewRequest = (data) => {
    setUpdatePending({});
    setUpdatePending(data);
    setModalUpdate(true);
  };

  const GetAccountUnaccounted = async (financialAccountId) => {
    setModalCreateLot((prevState) => ({
      ...prevState,
      sendingLoading: false,
      loading: true,
      show: true,
    }));

    setModalInsertLot((prevState) => ({
      ...prevState,
      loading: false,
    }));
    const response = await api.get(
      `/adminarea/GetPerFinancialAccountUnaccountedTransactions/${tenantProfile}/${financialAccountId}`
    );

    setModalCreateLot((prevState) => ({
      ...prevState,
      loading: false,
      data: response.data.map((x) => ({
        ...x,
        dateFormatted: x.transactionDate
          ? format(new Date(x.transactionDate), "dd/MMM/yyyy HH:mm", {
            locale: pt,
          })
          : null,
        valueFormatted: formatPrice(x.transactionValue),
        checked: false,
      })),
    }));
  };

  const handleChangeToLot = (status, itemId) => {
    setModalCreateLot((prevState) => ({
      ...prevState,
      data: modalCreateLot.data.map((x) => ({
        ...x,
        checked: x.transactionRequestId == itemId ? status : x.checked,
      })),
    }));
  };

  const handleChangeAllToLot = (status) => {
    setModalCreateLot((prevState) => ({
      ...prevState,
      data: modalCreateLot.data.map((x) => ({
        ...x,
        checked: status,
      })),
    }));
  };

  const handleCreateLot = async () => {
    const items = modalCreateLot.data.filter((x) => x.checked);
    if (items.length == 0) {
      toast.info(
        "Você precisa selecionar pelo menos um item para criar um lote"
      );
      return;
    }

    setModalCreateLot((prevState) => ({
      ...prevState,
      sendingLoading: true,
    }));

    const response = await api.post(
      `adminarea/CreateAccountingLot/${tenantProfile}`,
      items
    );

    const { status, message } = response.data;
    if (status) {
      setModalCreateLot((prevState) => ({
        ...prevState,
        sendingLoading: false,
        show: false,
      }));
      toast.success(`Lote criado com sucesso`);
      //history.push(`/admin/allotment`);
    } else {
      setModalCreateLot((prevState) => ({
        ...prevState,
        sendingLoading: false,
      }));
      toast.info(`Ocorreu um erro. ${message}`);
    }
  };

  const handleInsertIntoLotModal = async () => {
    const items = modalCreateLot.data.filter((x) => x.checked);
    if (items.length == 0) {
      toast.info(
        "Você precisa selecionar pelo menos um item para inserir em um lote"
      );
      return;
    }

    setModalInsertLot((prevState) => ({
      ...prevState,
      loading: true,
      show: true,
    }));

    const response = await api.get(
      `/adminarea/GetAllAccountingLots/${tenantProfile}/1/1000?${buildURLQuery({
        unnacounted: true,
      })}`
    );

    setModalInsertLot((prevState) => ({
      ...prevState,
      loading: false,
      data: response.data.entities.map((x) => ({
        id: x.accountingLotId,
        title: x.accountingLotCode,
      })),
    }));

    /* const response = await api.post(
      `adminarea/CreateAccountingLot/${tenantProfile}`,
      items
    );

    const { status, message } = response.data;
    if (status) {
      setModalCreateLot((prevState) => ({
        ...prevState,
        sendingLoading: false,
      }));
      toast.success(`Lote criado com sucesso`);
      history.push(`/admin/allotment`);
    } else {
      setModalCreateLot((prevState) => ({
        ...prevState,
        sendingLoading: false,
      }));
      toast.info(`Ocorreu um erro. ${message}`);
    } */
  };

  const handleInsertIntoLot = async (data) => {
    const { loteId } = data;
    const items = modalCreateLot.data.filter((x) => x.checked);
    console.log(data, items);
    if (items.length == 0 && loteId.trim() != "") {
      toast.info(
        "Você precisa selecionar pelo menos um item para inserir em um lote"
      );
      return;
    }

    setModalInsertLot((prevState) => ({
      ...prevState,
      sendingLoading: true,
    }));

    const response = await api.put(
      `adminarea/SetTransactionAccountingLotRange/${tenantProfile}/${loteId}`,
      items
    );

    const { status, message } = response.data;
    if (status) {
      setModalInsertLot((prevState) => ({
        ...prevState,
        sendingLoading: false,
        show: false,
      }));
      setModalCreateLot((prevState) => ({
        ...prevState,
        show: false,
      }));
      toast.success(`Itens adicionados com sucesso`);
      //history.push(`/admin/allotment`);
    } else {
      setModalInsertLot((prevState) => ({
        ...prevState,
        sendingLoading: false,
      }));
      toast.info(`Ocorreu um erro. ${message}`);
    }
  };

  const handleFind = (e) => {
    const vl = e.target.value;

    if (timeout) clearTimeout(timeout);
    setTimeOut(
      setTimeout(() => {
        setSearchString(vl);
      }, 400)
    );
  };

  const handleClear = () => {
    setSearchString("");
    setSearching(false);
  };

  const handleSearch = () => {
    setSearching(true);
  };

  const searchFunction = (data) => { };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Contas Financeiras</h1>
      </div>

      {/* Search */}
      <Row className="d-flex justify-content-center">
        <Col xs={11} sm={12} className="d-flex justify-content-end mb-2">
          {!!searching ? (
            <Form ref={formRef} onSubmit={searchFunction}>
              <InputGroup className="">
                <FormControl
                  type="text"
                  placeholder="Procurar aluno"
                  className="form-control inputSearch"
                  //value={searchString}
                  onChange={handleFind}
                  ref={searchRef}
                />
                <InputGroup.Append>
                  <Button variant="outline-danger" onClick={handleClear}>
                    <MdClear />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          ) : (
              <Button
                variant="outline-info"
                onClick={handleSearch}
                title="Clique para procurar"
              >
                <IoMdSearch />
              </Button>
            )}

          {/* Nova Conta */}
          <Button
            variant="outline-success"
            onClick={() => toast.info("funcionalidade não implementada")}
            title="Clique para adicionar nova conta"
            className="ml-2"
          >
            <MdAddCircleOutline />
          </Button>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center">
        <Col xs={11} sm={12}>
          <div className="table-responsive pt-3">
            <Table responsive striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Titulo</th>
                  <th>Lote Contábel</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={3}>
                      <div className="contentcenter">
                        <Spinner animation="border" /> Carregando
                      </div>
                    </td>
                  </tr>
                ) : pages.length === 0 ? (
                  <tr>
                    <td colSpan={3} className="text-center">
                      Nenhum registro
                    </td>
                  </tr>
                ) : (
                      pages.map((item, index) => (
                        console.log(item),
                        <tr key={item.financialAccountId}>
                          <td>
                            <Link to={`/admin/financial-account/transactions-done/${item.financialAccountId}`} className="btn btn-light">
                              {/* <AiFillMoneyCollect
                                size={16}
                                color="#149098"
                                className="mr-1"
                              /> */}
                              {item.alias}
                            </Link>
                          </td>
                          <td className="tdControl">
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                              >
                                <IoMdMenu size={22} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => handleViewRequest(item)}
                                >
                                  <MdUpdate
                                    size={16}
                                    color="#3b5998"
                                    className="mr-1"
                                  />
                              Atualizar
                            </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() =>
                                    GetAccountUnaccounted(item.financialAccountId)
                                  }
                                >
                                  <TiExport
                                    size={16}
                                    color="#1d7d20"
                                    className="mr-1"
                                  />
                              Criar Lote
                            </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    )}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>

      {/* Modal Atualizar Página */}
      <Modal
        className="mt-5"
        size="lg"
        show={modalUpdate}
        onHide={() => setModalUpdate(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Campanha</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col>
              <Card bg="light" className="text-center mb-4 bshadow">
                <Card.Header>
                  <b>Avaliar página</b>
                </Card.Header>
                <Card.Body className="py-4">
                  <Row>
                    <Col xs={12} sm={12}>
                      <ListGroup className="text-left">
                        {Object.entries(updatePending)
                          .filter((x) => x[1] !== null)
                          .map((x, index) => (
                            <ListGroup.Item
                              key={index}
                              dangerouslySetInnerHTML={{
                                __html: `<b>${x[0]}</b> - ${x[1]}`,
                              }}
                            ></ListGroup.Item>
                          ))}
                      </ListGroup>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Modal Criar Lote */}
      <Modal
        className="mt-5"
        size="lg"
        show={modalCreateLot.show}
        onHide={() =>
          setModalCreateLot((prevState) => ({ ...prevState, show: false }))
        }
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Itens não alocados
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col>
              <Card bg="light" className="text-center mb-4 bshadow">
                <Card.Header>
                  <b>Selecione os itens para criar o lote</b>{" "}
                </Card.Header>
                <Card.Body className="py-2">
                  <Row className="mb-2">
                    <Col>
                      <Button
                        onClick={() => handleChangeAllToLot(true)}
                        className="mr-2"
                      >
                        Marcar todos
                      </Button>
                      <Button
                        onClick={() => handleChangeAllToLot(false)}
                        variant="danger"
                        className="mr-2"
                      >
                        Desmarcar todos
                      </Button>
                      <Button
                        onClick={() => handleCreateLot()}
                        variant="success"
                        className="mr-2"
                        disabled={modalCreateLot.sendingLoading}
                      >
                        {modalCreateLot.sendingLoading
                          ? `Carregando...`
                          : `Criar Lote`}
                      </Button>
                      <Button
                        onClick={() => handleInsertIntoLotModal()}
                        variant="info"
                        className="mr-2"
                        disabled={modalInsertLot.loading}
                      >
                        {modalInsertLot.loading
                          ? `Carregando...`
                          : `Inserir em lote aberto`}
                      </Button>
                    </Col>
                  </Row>
                  <Table striped bordered hover variant="dark">
                    <thead>
                      <tr>
                        <th>-</th>
                        <th>Código da transação</th>
                        <th>Aluno</th>
                        <th>Campanha</th>
                        <th>Valor</th>
                        <th>Moderado por</th>
                        <th>Data</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modalCreateLot.loading ? (
                        <tr>
                          <td colSpan={7}>
                            <div className="contentcenter">
                              <Spinner animation="border" /> Carregando
                            </div>
                          </td>
                        </tr>
                      ) : modalCreateLot.data.length === 0 ? (
                        <tr>
                          <td colSpan={7} className="text-center">
                            Nenhum registro
                          </td>
                        </tr>
                      ) : (
                            modalCreateLot.data.map((item, index) => (
                              <tr key={item.transactionRequestId}>
                                <td>
                                  <Switch
                                    onChange={(status) =>
                                      handleChangeToLot(
                                        status,
                                        item.transactionRequestId
                                      )
                                    }
                                    checked={item.checked}
                                  />
                                </td>
                                <td>{item.transactionRequestId}</td>
                                <td>{item.originStudentName}</td>
                                <td>{item.originPageCampaignName}</td>
                                <td>{item.valueFormatted}</td>
                                <td>{item.moderatorName}</td>
                                <td>{item.dateFormatted}</td>
                              </tr>
                            ))
                          )}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Modal Inserir em um Lote */}
      <Modal
        className="mt-5"
        size="md"
        show={modalInsertLot.show}
        onHide={() =>
          setModalInsertLot((prevState) => ({
            ...prevState,
            show: false,
            loading: false,
          }))
        }
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-md">
            Lotes Abertos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col>
              <Card bg="light" className="text-center mb-4 bshadow">
                <Card.Header>
                  <b>Selecione o lote que você deseja adicionar os itens</b>{" "}
                </Card.Header>
                <Card.Body className="py-2">
                  <Row className="mb-2">
                    <Col>
                      <Form onSubmit={handleInsertIntoLot}>
                        <Select
                          name="loteId"
                          required
                          placeholder="Selecione..."
                          className="form-control mb-2"
                          options={modalInsertLot.data}
                        />
                        <Button
                          variant="primary"
                          type="submit"
                          className="mr-2"
                          disabled={modalInsertLot.sendingLoading}
                        >
                          {modalInsertLot.sendingLoading
                            ? `Carregando...`
                            : `Adicionar`}
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
