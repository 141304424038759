import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import api from "~/services/api";
import { formatPrice } from "~/util/format";
import urlsystem from "~/config/URLSystem";

import {
  Row,
  Col,
  Button,
  Card,
  Container,
  Table,
  Dropdown,
  Modal,
  Spinner,
  Jumbotron,
  Badge,
} from "react-bootstrap";

import PaginationComponent from "react-js-pagination";
import BoletoComponent from "~/components/BankSlip/BoletoComponent";
import parseDataBradesco from "~/util/parseDataBradesco";
import enumMetodPayment from "~/util/enumMetodPayment";

import { toast } from "react-toastify";
import { FaReceipt, FaExternalLinkAlt, FaFileInvoice } from "react-icons/fa";
import { IoMdMenu } from "react-icons/io";
import { format } from "date-fns";
import { Wrapper } from "./styles";

export default function Donor({ profile }) {
  const dispatch = useDispatch();
  const [donations, setDonations] = useState([]);
  const [infoDonations, setInfoDonations] = useState({});
  const [modal, setModal] = useState(false);

  const [totalDonations, setTotalDonations] = useState(null);
  const [loading, setLoading] = useState(true);

  const [activePage, setActivePage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(0);

  const [slipData, setSlipData] = useState(null);
  const [modalSlip, setModalSlip] = useState(false);

  /* Load initial */
  useEffect(() => {
    async function loadData() {
      try {
        const response = await api.get(`/giverarea/getgivertotals`);
        const data = response.data;

        setTotalDonations({
          ...data,
          totalDonationsFormatted: formatPrice(data.totalDonations || 0),
          totalNotProcessedFormatted: formatPrice(data.totalNotProcessed || 0),
          totalProcessedFormatted: formatPrice(data.totalProcessed || 0),
        });
      } catch (error) {
        if (!!error.response && error.response.status !== 401) {
          toast.error("Erro ao carregar a página.. tente novamente");
        }
      }
    }
    loadData();
  }, []);

  useEffect(() => {
    async function loadData() {
      try {
        setLoading(true);

        const response = await api.get(
          `/giverarea/getgiverdonations/${activePage}/${itemPerPage}`
        );
        const data = response.data;
        setTotalItens(data.indexSize);

        setDonations(
          data.entities
            .map((x) => ({
              ...x,
              urlFormatted: `${urlsystem}/student/${x.url}`,
              dateFormatted: format(new Date(x.date), "dd/MM/yyyy HH:mm"),
              valueFormatted: formatPrice(x.value),
              paymentMethodCodeFormatted: enumMetodPayment.find(
                (y) => y.id === x.paymentMethodCode
              )
                ? enumMetodPayment.find((y) => y.id === x.paymentMethodCode)
                    .title
                : "Método inválido",
            }))
            .sort((a, b) => b.date.localeCompare(a.date))
        );

        setLoading(false);
      } catch (error) {
        if (!!error.response && error.response.status !== 401) {
          toast.error("Erro ao carregar a página.. tente novamente");
        }
      }
    }
    loadData();
  }, [activePage, setItemPerPage]);

  const handleModalReceipt = async (data) => {
    setInfoDonations(data);
    setModal(true);
  };

  const handleModalBankSlip = async (data) => {
    setModalSlip(true);
    setSlipData(parseDataBradesco(data));
  };

  const renderMenu = (item) => {
    if (item.processed) {
      return (
        <Dropdown.Item
          onClick={() => {
            handleModalReceipt(item);
          }}
        >
          <FaReceipt size={18} color="#3b5998" /> Comprovante
        </Dropdown.Item>
      );
    }

    if (
      item.paymentMethodCode === 20 &&
      item.paymentStatus === 10 &&
      item.processed === false
    ) {
      return (
        <Dropdown.Item
          onClick={() => {
            handleModalBankSlip(item.paymentData);
          }}
        >
          <FaFileInvoice size={18} color="#3b5998" /> 2ª via do boleto
        </Dropdown.Item>
      );
    }
  };
  const componentRef = useRef();

  return (
    <Wrapper>
      <Container>
        <Row className="d-flex justify-content-center">
          <Col xs={12} lg={12} className="mb-4">
            <Card bg="dark" text="white" className="text-center mb-4 bshadow">
              <Card.Header>
                <b>Relatório de doações</b>​
              </Card.Header>
              <Card.Body className="py-4">
                <Row>
                  <Col>
                    <Table
                      striped
                      bordered
                      variant="dark"
                      responsive
                      hover
                      className="tableList"
                    >
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th>URL</th>
                          <th>Data</th>
                          <th>Valor</th>
                          <th>Status</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan={6}>
                              <div className="contentcenter">
                                <Spinner animation="border" /> Carregando
                              </div>
                            </td>
                          </tr>
                        ) : (
                          donations.map((item, index) => (
                            <tr key={index}>
                              <td>{item.studentName}</td>
                              <td>
                                <Button
                                  href={item.urlFormatted}
                                  target="_blank"
                                  variant="secondary"
                                >
                                  Doar Novamente <FaExternalLinkAlt />
                                </Button>
                              </td>
                              <td>{item.dateFormatted}</td>
                              <td>{item.valueFormatted}</td>
                              <td>
                                {item.processed ? "Completo" : "Pendente"}
                              </td>
                              <td className="tdControl">
                                <Dropdown drop="left">
                                  <Dropdown.Toggle
                                    variant="secondary"
                                    id="dropdown-basic"
                                  >
                                    <IoMdMenu size={22} />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {renderMenu(item)}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))
                        )}
                        {!!totalDonations && (
                          <tr>
                            <td colSpan={2}>
                              <b>Total das doações: </b>{" "}
                              {totalDonations.totalDonationsFormatted}
                            </td>
                            <td colSpan={2}>
                              <b>Total aguardando: </b>{" "}
                              {totalDonations.totalNotProcessedFormatted}
                            </td>
                            <td colSpan={2}>
                              <b>Todas processado: </b>{" "}
                              {totalDonations.totalProcessedFormatted}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Container
                  hidden={loading || donations.length === 0 ? true : false}
                >
                  <Row className="d-flex justify-content-center">
                    <Col
                      xs={10}
                      sm={5}
                      className="d-flex justify-content-center"
                    >
                      <PaginationComponent
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination mb-0"
                        activePage={activePage}
                        itemsCountPerPage={itemPerPage}
                        totalItemsCount={totalItens}
                        pageRangeDisplayed={5}
                        onChange={(e) => setActivePage(e)}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal Consulta */}
      <Modal
        className="mt-5"
        size="lg"
        show={modal}
        onHide={() => setModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Detalhes da doação
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            {/* Listagem das doações */}

            <Col xs={12}>
              {!!infoDonations.studentName && (
                <>
                  <Jumbotron ref={componentRef}>
                    <h4>
                      {!!infoDonations.studentName &&
                        `Doação feita para ${infoDonations.studentName}`}
                    </h4>
                    <p>
                      <b>Curso:</b> {infoDonations.studentCourse || ""}
                    </p>
                    <p>
                      <b>Link da doação:</b> {infoDonations.urlFormatted}
                    </p>
                    <p>
                      <b>Código da doação:</b> {infoDonations.donationId}
                    </p>
                    <p>
                      <b>Valor:</b> {infoDonations.valueFormatted}
                    </p>
                    <p>
                      <b>Data da doação:</b> {infoDonations.dateFormatted}
                    </p>
                    <p>
                      <b>Nome da campanha:</b> {infoDonations.campaignName}
                    </p>
                    <p>
                      <b>Método de pagamento: </b>
                      {infoDonations.paymentMethodCodeFormatted}
                    </p>
                    {!!infoDonations.isRecurrence ? (
                      <p>
                        <b>Doação recorrente</b>
                      </p>
                    ) : (
                      <p>
                        <b>Doação única</b>
                      </p>
                    )}
                    <p>
                      <b>Status do pagamento: </b>
                      {!!infoDonations.processed ? "Processado" : "Em análise"}
                    </p>
                    <Badge variant="warning">
                      * Não dedutível no imposto de renda
                    </Badge>
                  </Jumbotron>
                </>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Modal 2ª via Boleto */}
      <Modal
        className="mt-5"
        size="xl"
        show={modalSlip}
        onHide={() => setModalSlip(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Segunda via do boleto
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col xs={12}>
              {slipData != null ? (
                <BoletoComponent data={slipData} />
              ) : (
                <div className="contentcenter">
                  <Spinner animation="border" /> Carregando
                </div>
              )}
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Wrapper>
  );
}
