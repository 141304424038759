import { toast } from "react-toastify";
export default function copy(text) {
  try {
    var textArea = document.createElement("textarea");

    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;
    textArea.style.width = "2em";
    textArea.style.height = "2em";
    textArea.style.padding = 0;
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";
    textArea.style.background = "transparent";
    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.select();

    var successful = document.execCommand("copy");
    successful ? toast.info("Texto copiado") : toast.warn("Texto não copiado");
  } catch (err) {
    toast.error("Ocorreu um erro ao copiar");
  }

  document.body.removeChild(textArea);
}
