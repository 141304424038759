import React from "react";
import { Link } from "react-router-dom";
import { Form, Input } from "@rocketseat/unform";
import { IoMdArrowBack } from "react-icons/io";

import { Alert } from "react-bootstrap";

import logo from "~/assets/LogoBranco.png";

import api from "~/services/api";

import { toast } from "react-toastify";

export default function Recovery() {

  async function handleSubmit({ email }) {
    //dispatch(signUpRequest(name, email, password));
    try {
      const response = await api.post(`/authentication/RequestPasswordRecover/${email}`);
      const { status, message } = response.data;
      if (status) {
        toast.success(`Solicitação enviada. Se houver um usuário com o email ${email}, será enviado um link para recuperar a senha`);
      }
      else {
        toast.error(message)
      }
      //setModalLoading(false);
    } catch (error) {
      if (!!error.response && error.response.status !== 401) {
        toast.error("Erro na requisição.. tente novamente");
      } 
    }
  }

  return (
    <>
      {/* <div
        className="position-absolute w-100"
        style={{ maxWidth: "315px", overflow: "hidden", borderRadius: "13px" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="360"
          height="360"
          style={{ borderRadius: "13px" }}
          viewBox="0 0 200 200"
        >
          <rect fill="#ffffff" width="200" height="200" />
          <defs>
            <linearGradient
              id="a"
              gradientUnits="userSpaceOnUse"
              x1="88"
              y1="88"
              x2="0"
              y2="0"
            >
              <stop offset="0" stopColor="#064e77" />
              <stop offset="1" stopColor="#0a7dbe" />
            </linearGradient>
            <linearGradient
              id="b"
              gradientUnits="userSpaceOnUse"
              x1="75"
              y1="76"
              x2="168"
              y2="160"
            >
              <stop offset="0" stopColor="#8f8f8f" />
              <stop offset="0.09" stopColor="#b3b3b3" />
              <stop offset="0.18" stopColor="#c9c9c9" />
              <stop offset="0.31" stopColor="#dbdbdb" />
              <stop offset="0.44" stopColor="#e8e8e8" />
              <stop offset="0.59" stopColor="#f2f2f2" />
              <stop offset="0.75" stopColor="#fafafa" />
              <stop offset="1" stopColor="#FFFFFF" />
            </linearGradient>
            <filter id="c" x="0" y="0" width="200%" height="200%">
              <feGaussianBlur in="SourceGraphic" stdDeviation="12" />
            </filter>
          </defs>
          <polygon fill="url(#a)" points="0 174 0 0 174 0" />
          <path
            fill="#000"
            fillOpacity=".5"
            filter="url(#c)"
            d="M121.8 174C59.2 153.1 0 174 0 174s63.5-73.8 87-94c24.4-20.9 87-80 87-80S107.9 104.4 121.8 174z"
          />
          <path
            fill="url(#b)"
            d="M142.7 142.7C59.2 142.7 0 174 0 174s42-66.3 74.9-99.3S174 0 174 0S142.7 62.6 142.7 142.7z"
          />
        </svg>
      </div> */}
      <div className="p-4 position-relative" style={{ zIndex: 2, backgroundColor:"#003366", borderRadius:"12px"}}>
        <img src={logo} height="60px" className="my-3" />
        <h3 className="mt-3 text-light">Recuperar a senha</h3>
        <h6 className="text-light">Entre com o seu e-mail</h6>
        <Form onSubmit={handleSubmit}>
          {/* <Alert variant="warning">
            Funcionalidade disponível apenas para doadores
          </Alert> */}
          <Input name="email" type="email" placeholder="E-mail" style={{backgroundColor:"#f3f2f3"}} />
          <button type="submit" id="loginBtn" >Recuperar Senha</button>
          <Link to="/login" style={{color:"#f3f2f3"}}>
            <IoMdArrowBack size={16}  />
            Voltar
          </Link>
        </Form>
      </div>
    </>
  );
}
