import styled from "styled-components";

export const Wrapper = styled.div`
  .card-body {
    display: flex;
    flex-direction: column;

    /* @media (min-width: 1200px) {
      min-height: 25vh;
    }

    @media (max-width: 1199px) {
      min-height: 29vh;
    }
    @media (max-width: 990px) {
      min-height: 31vh;
    }
    @media (max-width: 777px) {
      min-height: 33vh;
    } */
  }
`;
