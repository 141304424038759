export function signInRequestDoador(email, password) {
  return {
    type: "@auth/SIGN_IN_REQUEST_DONOR",
    payload: { email, password },
  };
}

export function signInRequestAdmin(email, password) {
  return {
    type: "@auth/SIGN_IN_REQUEST_ADMIN",
    payload: { email, password },
  };
}

export function signInRequestAluno(campus, ra, password, birthDate) {
  return {
    type: "@auth/SIGN_IN_REQUEST_STUDENT",
    payload: { campus, ra, password, birthDate },
  };
}

export function signInSuccess(token, user, type) {
  return {
    type: "@auth/SIGN_IN_SUCCESS",
    payload: { token, user, type },
  };
}

export function signUpRequest(name, email, password, confirmPassword) {
  return {
    type: "@auth/SIGN_UP_REQUEST",
    payload: {
      name,
      email,
      password,
      confirmPassword,
    },
  };
}

export function signFailure() {
  return {
    type: "@auth/SIGN_FAILURE",
  };
}

export function signOut() {
  return {
    type: "@auth/SIGN_OUT",
  };
}
