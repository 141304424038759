import React, { useEffect, useState, useMemo } from "react";

import api from "~/services/api";
import axios from "axios";

import { formatPrice } from "~/util/format";
import urlsystem from "~/config/URLSystem";
import { Form, Input, Select } from "@rocketseat/unform";

import Switch from "react-switch";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import {
  Row,
  Col,
  Button,
  Card,
  Container,
  InputGroup,
  ListGroup,
  Table,
  Dropdown,
  Modal,
  Spinner,
  Jumbotron,
  Alert,
  OverlayTrigger,
  Tooltip,
  Tabs,
  Tab,
} from "react-bootstrap";

import { toast } from "react-toastify";
import copy from "~/util/copy";
import youtube_parser from "~/util/youtube";

import enumState from "~/util/enumState";
import enumTransactionStatus from "~/util/enumTransactionStatus";
import enumTransactionType from "~/util/enumTransactionType";

import CurrencyInput from "~/util/CurrencyInput";

import CropImage from "~/components/CropImage";

import PaginationComponent from "react-js-pagination";

import { FaDonate, FaFacebook, FaCopy, FaMoneyBillWave } from "react-icons/fa";
import { IoLogoWhatsapp, IoMdMenu } from "react-icons/io";
import {
  MdUpdate,
  MdLink,
  MdDeleteForever,
  MdClose,
  MdInfoOutline,
} from "react-icons/md";
import { FiEye, FiEyeOff } from "react-icons/fi";

import { format } from "date-fns";
import history from "~/services/history";

import { Wrapper } from "./styles";

export default function Student({ profile }) {
  const [donations, setDonations] = useState([]);
  const [userDonations, setUserDonations] = useState(null);

  const [infoDonations, setInfoDonations] = useState(null);
  const [detailDonations, setDetailDonations] = useState([]);

  const [modalLoading, setModalLoading] = useState(false);
  const [modal, setModal] = useState(false);

  const [modalCreatePage, setModalCreatePage] = useState(false);

  const [modalUpdateInfo, setModalUpdateInfo] = useState(null);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [statusCancel, setStatusCancel] = useState(0);

  const [smShow, setSmShow] = useState(false);

  const [loading, setLoading] = useState(true);

  const [messageErrorSevenPag, setErrorMessage] = useState(
    "Erro ao obter informação na financeira. Tente mais tarde"
  );

  const [isPrivateEdit, setIsPrivateEdit] = useState(false);
  const [isPublishEdit, setIsPublishEdit] = useState(false);

  const [isPrivateCreate, setIsPrivateCreate] = useState(false);
  const [isPublishCreate, setIsPublishCreate] = useState(false);

  const [viewAllValues, setViewAllValue] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(0);

  const [donationsActivePage, setDonationsActivePage] = useState(0);
  const [donationsItemPerPage, setDonationsItemPerPage] = useState(10);
  const [donationsTotalItens, setDonationsTotalItens] = useState(0);
  const [donationsId, setDonationsId] = useState(null);

  const [transactions, setTransactions] = useState([]);
  const [transactionLoading, setTransactionLoading] = useState(false);

  const [updatePending, setUpdatePending] = useState(null);

  const [finalities, setFinalities] = useState([]);

  const [valueRequest, setValueRequest] = useState("");

  const [campaigns, setCampaigns] = useState([]);
  const [pageDefault, setPageDefault] = useState({});

  /* tabKey */
  const [key, setKey] = useState("");

  /* Load data pages */
  useEffect(() => {
    loadData();
  }, [activePage, setItemPerPage]);

  /* Load Info Page */
  useEffect(() => {
    async function loadData() {
      if (donationsId != null) {
        loadDataInfoValuesDonations();
      }
    }
    loadData();
  }, [donationsId]);

  /* Load All Donations */
  useEffect(() => {
    async function loadData() {
      if (donationsActivePage != 0 && donationsId != null) {
        try {
          setModalLoading(true);
          const response = await api.get(
            `/StudentArea/GetPageDonations/${donationsId}/${donationsActivePage}/${donationsItemPerPage}`
          );
          const dt = response.data;
          //console.log(dt);
          setDonationsTotalItens(dt.indexSize);

          setDetailDonations(
            dt.entities
              .map((x) => ({
                ...x,
                dateFormatted: format(new Date(x.date), "dd/MM/yyyy HH:mm"),
                valueFormatted: formatPrice(x.value),
              }))
              .sort((a, b) => b.date.localeCompare(a.date))
          );

          setModalLoading(false);
        } catch (error) {
          if (!!error.response && error.response.status !== 401) {
            toast.error("Erro ao carregar a página.. tente novamente");
          }
        }
      }
    }
    loadData();
  }, [donationsId, donationsActivePage, donationsItemPerPage]);

  /* Load All Transactions */
  useEffect(() => {
    async function loadData() {
      if (donationsId != null) {
        try {
          setTransactionLoading(true);

          const response = await api.get(
            //`/studentarea/GetInAnalysisPageTransactionRequests/${donationsId}`
            `/studentarea/getallpagetransactionrequests/${donationsId}`
          );
          const dt = response.data;

          setTransactions(
            dt.map((x) => ({
              ...x,
              statusValue: enumTransactionStatus.find((y) => y.id === x.status)
                .label,
              transactionTypeValue: finalities.find(
                (y) => y.id === x.redeemFinalityId
              ).title,
              requestValueFormatted: !!x.requestValue
                ? formatPrice(x.requestValue)
                : "",
              approvedValueFormatted: !!x.approvedValue
                ? formatPrice(x.approvedValue)
                : "",
            }))
          );

          setTransactionLoading(false);
        } catch (error) {
          if (!!error.response && error.response.status !== 401) {
            toast.error("Erro ao carregar a página.. tente novamente");
            setTransactionLoading(false);
          }
        }
      }
    }
    loadData();
  }, [donationsId]);

  const totalTransactionsPending = useMemo(
    () =>
      formatPrice(
        transactions
          .filter((x) => x.status === 10)
          .reduce((total, item) => (total = total + item.requestValue), 0)
      ),
    [transactions]
  );

  const loadData = async () => {
    try {
      setLoading(true);
      await axios
        .all([
          api.get(
            `/StudentArea/GetStudentDonationPages/${activePage}/${itemPerPage}`
          ),
          api.get(`/studentarea/getstudenttotals`),
        ])
        .then(
          axios.spread((...responses) => {
            const dataDonations = responses[0].data;
            setTotalItens(dataDonations.indexSize);
            setDonations(
              dataDonations.entities.map((x) => ({
                ...x,
                urlFormatted: `${urlsystem}/student/${x.url}`,
              }))
            );

            const udt = responses[1].data;
            setUserDonations({
              ...udt,
              totalDonationsFormatted: formatPrice(udt.totalDonations),
              totalAvailableFormatted: formatPrice(udt.totalAvailable),
              totalNotProcessedFormatted: formatPrice(udt.totalNotProcessed),
              totalProcessedFormatted: formatPrice(udt.totalProcessed),
              totalOutFlowFormatted: formatPrice(udt.totalOutflow),
            });

            setLoading(false);
          })
        )
        .catch((errors) => {
          toast.error("Erro ao carregar os dados");
        });
    } catch (error) {
      if (!!error.response && error.response.status !== 401) {
        toast.error("Erro ao carregar a página");
      }
    }
  };

  const loadDataInfoValuesDonations = async () => {
    try {
      setInfoDonations(null);
      const response = await api.get(
        `/StudentArea/GetPageTotals/${donationsId}`
      );
      const dt = response.data;

      setInfoDonations({
        ...dt,
        urlFormatted: `${urlsystem}/student/${dt.url}`,
        totalDonationsFormatted: formatPrice(dt.totalDonations),
        totalAvailableFormatted: formatPrice(dt.totalAvailable),
        totalNotProcessedFormatted: formatPrice(dt.totalNotProcessed),
        totalProcessedFormatted: formatPrice(dt.totalProcessed),
        totalOutFlowFormatted: formatPrice(dt.totalOutflow),
      });
    } catch (error) {
      if (!!error.response && error.response.status !== 401) {
        toast.error("Erro ao carregar a página.. tente novamente");
      }
    }
  };

  const handleChangeDonations = async (id) => {
    const response = await api.get(
      `/studentarea/GetPageRedeemFinalities/${id}`
    );
    const dt = response.data;

    setFinalities(
      dt.map((x) => ({
        id: x.redeemFinalityId,
        value: x.redeemFinalityId,
        title: x.title,
      }))
    );

    setDonationsActivePage(1);
    setDonationsId(id);
    setKey("extrato");
    setModal(true);
  };

  const handleUpdateCampaignModal = async (obj) => {
    setModalUpdate(true);
    setModalUpdateInfo(null);
    setModalLoading(true);

    setUpdatePending(null);
    const { published, privatePage, pageId } = obj;

    const response = await api.get(
      `/studentarea/getdonationpageupdaterequest/${pageId}`
    );
    const dt = response.data;
    if (response.status === 200) {
      setUpdatePending(dt);
    }

    setIsPrivateEdit(privatePage || false);
    setIsPublishEdit(published || false);

    setModalUpdateInfo(obj);

    setModalLoading(false);
  };

  const handleUpdatePrivatePage = async (status, handleId) => {
    confirmAlert({
      title: "Confirme",
      message: `Deseja realmente tornar esta página ${status ? "privada" : "pública"
        } ?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            setModalLoading(true);
            try {
              const response = await api.put(
                `/studentarea/changepageprivate/${handleId}`
              );
              const data = response.data;

              const { message, id, entity } = data;
              if (response.status == 200 && data.status) {
                setIsPrivateEdit(status);
                setDonations(
                  donations.map((x) =>
                    x.pageId == id
                      ? {
                        ...entity,
                        urlFormatted: `${urlsystem}/student/${entity.url}`,
                      }
                      : {
                        ...x,
                      }
                  )
                );
                toast.success(`Status alterado`);
              } else {
                toast.error(`Erro na atualização. ${message}`);
              }
            } catch (error) {
              toast.error(`Erro na comunicação com o servidor.`);
            } finally {
              setModalLoading(false);
            }
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });
  };

  const handleUpdatePublishPage = async (status, handleId) => {
    confirmAlert({
      title: "Confirme",
      message: `Deseja realmente ${status ? "publicar" : "colocar em rascunho"
        } esta página?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            setModalLoading(true);
            try {
              const response = await api.put(
                `/studentarea/changePagePublished/${handleId}`
              );
              const data = response.data;

              const { message, id, entity } = data;
              if (response.status == 200 && data.status) {
                setIsPublishEdit(status);
                setDonations(
                  donations.map((x) =>
                    x.pageId == id
                      ? {
                        ...entity,
                        urlFormatted: `${urlsystem}/student/${entity.url}`,
                      }
                      : {
                        ...x,
                      }
                  )
                );
                toast.success(`Status alterado`);
              } else {
                toast.error(`Erro na atualização. ${message}`);
              }
            } catch (error) {
              toast.error(`Erro na comunicação com o servidor.`);
            } finally {
              setModalLoading(false);
            }
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });
  };

  const handleGenerateNewUrl = async (handleId) => {
    confirmAlert({
      title: "Confirme",
      message: `Deseja realmente alterar o link dessa página (o atual link ficará indisponível)?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            setModalLoading(true);
            try {
              const response = await api.put(
                `/StudentArea/RegeneratePageUrl/${handleId}`
              );
              const data = response.data;
              const { message, id, entity } = data;
              if (response.status == 200 && data.status) {
                setModalUpdateInfo({
                  ...entity,
                  urlFormatted: `${urlsystem}/student/${entity.url}`,
                });
                setDonations(
                  donations.map((x) =>
                    x.pageId == id
                      ? {
                        ...entity,
                        urlFormatted: `${urlsystem}/student/${entity.url}`,
                      }
                      : {
                        ...x,
                      }
                  )
                );
                toast.success(`Link alterado`);
              } else {
                toast.error(`Erro na mudança do link. ${message}`);
              }
            } catch (error) {
              toast.error("Erro na comunicação com o servidor");
            } finally {
              setModalLoading(false);
            }
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });
  };

  const handleUpdatePage = async (data) => {
    setModalLoading(true);
    const { pageDescription, pageId, text, videoUrl } = data;

    const videoIsValid = !!videoUrl.trim() ? !!youtube_parser(videoUrl) : true;

    if (videoIsValid) {
      const objData = {
        pageId,
        text,
        pageDescription,
        videoUrl: videoUrl.trim(),
      };

      try {
        const response = await api.put(
          `/StudentArea/UpdateDonationPageText`,
          objData
        );
        const data = response.data;
        const { message, id, entity } = data;
        if (response.status == 200 && data.status) {
          setDonations(
            donations.map((x) =>
              x.pageId == id
                ? {
                  ...entity,
                  urlFormatted: `${urlsystem}/student/${entity.url}`,
                }
                : {
                  ...x,
                }
            )
          );
          toast.success(
            "Alteração enviada. As mudanças só atualizarão sua página após a aprovação do moderador"
          );
          setModalUpdateInfo({});
          setModalUpdate(false);
        } else {
          toast.error(`Erro ao atualizar.. ${message}`);
        }
      } catch (error) {
        if (!!error.response && error.response.status !== 401) {
          toast.error("Erro ao atualizar.. tente mais tarde");
        }
      } finally {
        setModalLoading(false);
      }
    } else {
      toast.error("Link do YouTube inválido");
      setModalLoading(false);
    }
  };

  const handleCancelUpdate = async (data) => {
    confirmAlert({
      title: "Confirme",
      message: `Deseja realmente ${statusCancel === 20 ? "editar" : "cancelar"
        } essa atualização?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            setModalLoading(true);
            const { donationPageId } = data;
            try {
              const response = await api.delete(
                `/studentarea/cancelupdatestudentdonationpage/${donationPageId}`
              );
              const data = response.data;
              const { message, status } = data;
              if (response.status == 200 && status) {
                if (statusCancel === 10) {
                  toast.success("Atualização cancelada");
                  setUpdatePending(null);
                } else if (statusCancel === 20) {
                  const { pageDescription, text, videoUrl } = updatePending;
                  setModalUpdateInfo({
                    ...modalUpdateInfo,
                    pageDescription,
                    text,
                    videoUrl,
                  });
                  setUpdatePending(null);
                }
              } else {
                toast.error(`Erro ao cancelar.. ${message}`);
              }
            } catch (error) {
              if (!!error.response && error.response.status !== 401) {
                toast.error("Erro ao cancelar.. tente mais tarde");
              }
            } finally {
              setModalLoading(false);
            }
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });
  };

  const closeModal = () => {
    setDonationsId(null);
    setModal(false);
  };

  const handleRequestRescueModal = async () => {
    setSmShow(true);
    setValueRequest(infoDonations.totalAvailable);
  };

  const handleSendRescue = async (data) => {
    const { finalidade, pageId } = data;
    const valor = valueRequest;

    if (valor > infoDonations.totalAvailable) {
      toast.error("Valor solicitado não disponível");
      return;
    }

    const json = {
      redeemFinalityId: finalidade,
      originPageId: pageId,
      destinationPageId: null,
      requestValue: valor,
    };

    try {
      const response = await api.post(`/studentarea/RequestTransaction`, json);

      const data = response.data;
      const { message, status, entity } = data;
      if (response.status == 200 && status) {
        loadDataInfoValuesDonations();

        setTransactions([
          ...transactions,
          {
            ...entity,
            statusValue: enumTransactionStatus.find(
              (y) => y.id === entity.status
            ).label,
            transactionTypeValue: finalities.find(
              (y) => y.id === entity.redeemFinalityId
            ).title,
            requestValueFormatted: !!entity.requestValue
              ? formatPrice(entity.requestValue)
              : "",
            approvedValueFormatted: !!entity.approvedValue
              ? formatPrice(entity.approvedValue)
              : "",
          },
        ]);

        toast.info(
          "Resgate de fundo solicitado. Aguarde um contato de confirmação"
        );
        setKey("transacoes");
      } else {
        toast.error(`Erro na solicitação.. ${message}`);
      }
    } catch (error) {
      if (!!error.response && error.response.status !== 401) {
        toast.error("Erro na solicitação.. tente mais tarde");
      }
    }

    setSmShow(false);
  };

  const newPageModal = async () => {
    setPageDefault({});
    setCampaigns([]);
    setIsPublishCreate(true);
    setIsPrivateCreate(false);
    setModalLoading(false);
    setModalCreatePage(true);
    const response = await api.get(`/StudentArea/GetStudentCampaigns`);
    const dt = response.data;

    setCampaigns(
      dt.map((x) => ({
        ...x,
        id: x.campaignId,
        value: x.campaignId,
        title: x.name,
      }))
    );
  };

  const changeCampaign = async (e) => {
    setPageDefault(campaigns.find((x) => x.campaignId === e.target.value));
  };

  const handleCreatePage = async (data) => {
    const { campaignId, pageDescription, text, videoUrl } = data;

    const videoIsValid = !!videoUrl.trim() ? !!youtube_parser(videoUrl) : true;

    if (videoIsValid) {
      setModalLoading(true);
      const json = {
        campaignId,
        pageDescription: pageDescription.trim(),
        text: text.trim(),
        videoUrl: videoUrl.trim(),
        privatePage: isPrivateCreate,
        published: isPublishCreate,
      };

      try {
        const response = await api.post(
          `/studentarea/createdonationpage`,
          json
        );
        const data = response.data;
        const { message, status, entity } = data;
        if (response.status == 200 && status) {
          loadData();

          toast.success("Página enviada para moderação");
          setModalCreatePage(false);
        } else {
          toast.error(`Erro ao criar.. ${message}`);
        }
      } catch (error) {
        if (!!error.response && error.response.status !== 401) {
          toast.error("Erro ao criar.. tente mais tarde");
        }
      } finally {
        setModalLoading(false);
      }
    } else {
      toast.error("Link do YouTube inválido");
    }
  };

  const verifyDeletable = async (data) => {
    const { pageId, pageDescription } = data;
    confirmAlert({
      title: "Confirme",
      message: `Deseja realmente a página ${pageDescription}?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              const response = await api.get(
                `/studentarea/isdeletablepage/${pageId}`
              );
              const dt = response.data;
              const { message, status, entity } = dt;
              if (response.status == 200) {
                if (status) {
                  const responseDel = await api.delete(
                    `/studentarea/DeleteDonationPage/${pageId}`
                  );
                  const dta = responseDel.data;
                  if (responseDel.status == 200) {
                    if (dta.status) {
                      toast.success("Página excluida com sucesso");
                      loadData();
                    } else {
                      toast.success(`Não foi possível excluir. ${dta.message}`);
                    }
                  }
                } else {
                  toast.error(
                    "Não foi possível excluir. Existem doações nesta página"
                  );
                }
              } else {
                toast.error(`Erro na mudança do link. ${message}`);
              }
            } catch (error) {
              toast.error("Erro na comunicação com o servidor");
            }
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });
  };

  const dismissAlert = async (pageId) => {
    const response = await api.put(
      `/studentarea/setpageupdateviewed/${pageId}`
    );
    const data = response.data;
    const { message, status, id, entity } = data;
    if (response.status == 200 && status) {
      setUpdatePending({ ...updatePending, viewed: true });
    } else {
      toast.error(message);
    }
  };

  return (
    <Wrapper>
      <Container>
        <Row className="d-flex justify-content-center">
          {/* Col info User */}
          <Col xs={12} md={4} className="mb-4">
            <Card bg="light" className="bshadow">
              <Card.Header>
                <Col xs={12} sm={12} className="text-center py-1">
                  <CropImage
                    data={profile.avatar}
                    route="/studentarea/uploadimage"
                  />
                </Col>
              </Card.Header>
              <Card.Body>
                <Card.Title>{profile.name}</Card.Title>
                <hr />
                <Card.Text>
                  <b>Curso:</b> {profile.curso}
                  <br />
                  <b>Campus:</b> {profile.campus}
                  <br />
                  <b>E-mail:</b> {profile.email}
                  <br />
                  <b>RA:</b> {profile.ra}
                </Card.Text>
              </Card.Body>
              <Card.Footer className="text-muted">
                {!!userDonations ? (
                  userDonations.sevenPagConnectionSuccess ? (
                    <ListGroup>
                      <ListGroup.Item
                        className="text-primary"
                        hidden={!viewAllValues}
                      >
                        <b>Todas as Doações: </b>{" "}
                        {userDonations.totalDonationsFormatted}
                      </ListGroup.Item>
                      <ListGroup.Item
                        className="text-danger"
                        hidden={!viewAllValues}
                      >
                        <>
                          <OverlayTrigger
                            placement={"right"}
                            overlay={
                              <Tooltip id={`tooltip-${"right"}`}>
                                Doação ainda não processada pelo sistema
                                bancário
                              </Tooltip>
                            }
                          >
                            <div>
                              <b>Doação Pendente: </b>{" "}
                              {userDonations.totalNotProcessedFormatted}
                              <MdInfoOutline
                                size={15}
                                style={{
                                  marginLeft: "2px",
                                  marginBottom: "1px",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          </OverlayTrigger>
                        </>
                      </ListGroup.Item>
                      <ListGroup.Item
                        className="text-info"
                        hidden={!viewAllValues}
                      >
                        <b>Doação Processada: </b>{" "}
                        {userDonations.totalProcessedFormatted}
                      </ListGroup.Item>
                      <ListGroup.Item
                        className="text-secondary"
                        hidden={!viewAllValues}
                      >
                        <b>Saída de Caixa: </b>{" "}
                        {userDonations.totalOutFlowFormatted}
                      </ListGroup.Item>
                      <ListGroup.Item className="bg-success text-light">
                        <h6>
                          <b>Saldo Disponível: </b>{" "}
                          {userDonations.totalAvailableFormatted}
                        </h6>
                      </ListGroup.Item>
                      <ListGroup.Item className="contentcenter">
                        {viewAllValues ? (
                          <Button
                            id="dropdown-button-icon"
                            className="text-dark my-0 py-0"
                            onClick={() => setViewAllValue(false)}
                          >
                            Ver menos{" "}
                            <FiEyeOff size={20} style={{ marginLeft: "8px" }} />
                          </Button>
                        ) : (
                          <Button
                            id="dropdown-button-icon"
                            className="text-dark my-0 py-0"
                            onClick={() => setViewAllValue(true)}
                          >
                            Ver mais{" "}
                            <FiEye size={20} style={{ marginLeft: "8px" }} />
                          </Button>
                        )}
                      </ListGroup.Item>
                    </ListGroup>
                  ) : (
                    <Alert variant="danger">{messageErrorSevenPag}</Alert>
                  )
                ) : (
                  <div className="contentcenter">
                    <Spinner animation="border" /> Carregando
                  </div>
                )}
              </Card.Footer>
            </Card>
          </Col>

          {/* CardListagem */}
          <Col xs={12} md={8} className="mb-4">
            <Card bg="dark" text="white" className="text-center mb-4 bshadow">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <h5>Minhas páginas</h5>
                <Button variant="outline-light" onClick={newPageModal}>
                  Criar nova página
                </Button>
              </Card.Header>
              <Card.Body className="py-4">
                <Row>
                  <Col>
                    <Table
                      striped
                      bordered
                      variant="dark"
                      responsive
                      hover
                      className="tableList"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Campanha</th>
                          <th>Descrição</th>
                          <th>Link</th>
                          <th>Ativo</th>
                          <th>Publicado</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan={7}>
                              <div className="contentcenter">
                                <Spinner animation="border" /> Carregando
                              </div>
                            </td>
                          </tr>
                        ) : donations.length == 0 ? (
                          <tr>
                            <td colSpan={7}>Nenhuma página</td>
                          </tr>
                        ) : (
                          donations.map((item, index) => (
                            <tr key={index}>
                              <td>{index}</td>
                              <td>{item.campaignName}</td>
                              <td>{item.pageDescription}</td>
                              <td>
                                {!!item.url ? (
                                  <>
                                    <p className="text-break d-none d-md-block">
                                      {item.urlFormatted}
                                    </p>
                                    <FaCopy
                                      size={18}
                                      color="#dfdfdf"
                                      className="d-block d-md-none"
                                      onClick={() => copy(item.urlFormatted)}
                                    />
                                  </>
                                ) : (
                                  <p className="text-break">Url não definida</p>
                                )}
                              </td>
                              <td>{item.active ? "Sim" : "Não"}</td>
                              <td>{item.published ? "Sim" : "Não"}</td>
                              <td className="tdControl">
                                <Dropdown drop="left">
                                  <Dropdown.Toggle
                                    variant="secondary"
                                    id="dropdown-basic"
                                  >
                                    <IoMdMenu size={22} />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Header>{`${item.pageDescription}`}</Dropdown.Header>
                                    <Dropdown.Item
                                      onClick={() => {
                                        history.push(`/student/${item.url}`);
                                      }}
                                    >
                                      <MdLink size={18} color="#ff3333" /> Abrir
                                      página
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleUpdateCampaignModal(item)
                                      }
                                    >
                                      <MdUpdate size={18} color="#17a2b8" />{" "}
                                      Editar página
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => verifyDeletable(item)}
                                    >
                                      <MdDeleteForever
                                        size={18}
                                        color="#ff1244"
                                      />{" "}
                                      Excluir página
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() => copy(item.urlFormatted)}
                                    >
                                      <FaCopy size={18} color="#8c8c8c" />{" "}
                                      Copiar Link
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      onClick={() =>
                                        handleChangeDonations(item.pageId)
                                      }
                                    >
                                      <FaDonate size={18} color="#24ca68" />{" "}
                                      Visualizar Doações
                                    </Dropdown.Item>

                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                      target="_blank"
                                      href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                                        item.urlFormatted
                                      )}`}
                                    >
                                      <IoLogoWhatsapp
                                        size={18}
                                        color="#34af23"
                                      />{" "}
                                      Enviar no WhatsApp
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      target="_blank"
                                      href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                                        item.urlFormatted
                                      )}&amp`}
                                    >
                                      <FaFacebook size={18} color="#3b5998" />{" "}
                                      Enviar no Facebook
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                <Container
                  hidden={loading || donations.length == 0 ? true : false}
                >
                  <Row
                    className={`justify-content-center ${totalItens <= itemPerPage ? "d-none" : "d-flex"
                      }`}
                  >
                    <Col
                      xs={10}
                      sm={5}
                      className="d-flex justify-content-center"
                    >
                      <PaginationComponent
                        itemClass="page-item"
                        linkClass="page-link"
                        innerClass="pagination mb-0"
                        activePage={activePage}
                        itemsCountPerPage={itemPerPage}
                        totalItemsCount={totalItens}
                        pageRangeDisplayed={5}
                        onChange={(e) => setActivePage(e)}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal Consulta */}
      <Modal
        className="mt-5"
        size="lg"
        show={modal}
        onHide={() => closeModal()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Detalhes das suas doações
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            {/* Cabeçalho */}
            <Col xs={12}>
              <Jumbotron className="py-3 mb-3">
                {!!infoDonations ? (
                  <>
                    <h3>
                      {!!infoDonations.people &&
                        `Doações feita para ${infoDonations.people}`}
                    </h3>
                    <p className="text-break">
                      Doações feitas através da página{" "}
                      <b>{infoDonations.urlFormatted}</b>
                    </p>
                    <Table striped bordered hover responsive size="sm">
                      <thead>
                        <tr>
                          <th className="text-primary">Total de Doações</th>
                          <th className="text-danger">
                            <>
                              <OverlayTrigger
                                placement={"right"}
                                overlay={
                                  <Tooltip id={`tooltip-${"right"}`}>
                                    Doação ainda não processada pelo sistema
                                    bancário
                                  </Tooltip>
                                }
                              >
                                <div>
                                  Doação Pendente
                                  <MdInfoOutline
                                    size={15}
                                    style={{
                                      marginLeft: "2px",
                                      marginBottom: "1px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </OverlayTrigger>
                            </>
                          </th>
                          <th className="text-info">Doação Processada</th>
                          <th className="text-warning">Transação análise</th>
                          <th className="text-secondary">
                            <>
                              <OverlayTrigger
                                placement={"left"}
                                overlay={
                                  <Tooltip id={`tooltip-${"left"}`}>
                                    Valor total resgatado
                                  </Tooltip>
                                }
                              >
                                <div>
                                  Saída de Caixa
                                  <MdInfoOutline
                                    size={15}
                                    style={{
                                      marginLeft: "2px",
                                      marginBottom: "1px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </div>
                              </OverlayTrigger>
                            </>
                          </th>
                          <th className="bg-success text-light">
                            Saldo Disponível
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{infoDonations.totalDonationsFormatted}</td>
                          <td>
                            {infoDonations.sevenPagConnectionSuccess
                              ? infoDonations.totalNotProcessedFormatted
                              : messageErrorSevenPag}
                          </td>
                          <td>
                            {infoDonations.sevenPagConnectionSuccess
                              ? infoDonations.totalProcessedFormatted
                              : messageErrorSevenPag}
                          </td>
                          <td>{totalTransactionsPending}</td>
                          <td>{infoDonations.totalOutFlowFormatted}</td>
                          <td className="bg-success text-light">
                            {infoDonations.sevenPagConnectionSuccess
                              ? infoDonations.totalAvailableFormatted
                              : messageErrorSevenPag}
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    {infoDonations.totalAvailable >= 50 &&
                      infoDonations.sevenPagConnectionSuccess && (
                        <Button
                          variant="info"
                          onClick={handleRequestRescueModal}
                        >
                          Resgatar dinheiro <FaMoneyBillWave />
                        </Button>
                      )}
                  </>
                ) : (
                  <div className="contentcenter">
                    <Spinner animation="border" /> Carregando
                  </div>
                )}
              </Jumbotron>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {/* Listagem das doações */}
            <Col xs={12}>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="extrato" title="Extrato">
                  <Table striped bordered hover responsive variant="dark">
                    <thead>
                      <tr>
                        <td width="20%">Doador</td>
                        <td width="20%">Data</td>
                        <td width="20%">Valor</td>
                        <td width="20%">Disponível</td>
                      </tr>
                    </thead>
                    <tbody>
                      {modalLoading ? (
                        <tr>
                          <td colSpan={4}>
                            <div className="contentcenter">
                              <Spinner animation="border" /> Carregando
                            </div>
                          </td>
                        </tr>
                      ) : detailDonations.length > 0 ? (
                        detailDonations.map((it, i) => (
                          <tr key={i}>
                            <td>{it.name}</td>
                            <td>{it.dateFormatted}</td>
                            <td>{it.valueFormatted}</td>
                            <td>
                              {it.sevenPagConnectionSuccess
                                ? it.processed
                                  ? `Sim`
                                  : `Não`
                                : messageErrorSevenPag}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr align="center">
                          <td colSpan={4}>Nenhuma doação</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {/* Paginação */}
                  <Row>
                    <Col>
                      <Container
                        hidden={
                          modalLoading || detailDonations.length == 0
                            ? true
                            : false
                        }
                      >
                        <Row
                          className={`justify-content-center ${donationsTotalItens <= donationsItemPerPage
                              ? "d-none"
                              : "d-flex"
                            }`}
                        >
                          <Col
                            xs={10}
                            sm={5}
                            className="d-flex justify-content-center"
                          >
                            <PaginationComponent
                              itemClass="page-item"
                              linkClass="page-link"
                              innerClass="pagination mb-0"
                              activePage={donationsActivePage}
                              itemsCountPerPage={donationsItemPerPage}
                              totalItemsCount={donationsTotalItens}
                              pageRangeDisplayed={5}
                              onChange={(e) => setDonationsActivePage(e)}
                            />
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Tab>
                <Tab eventKey="transacoes" title="Transações">
                  <Table striped bordered hover responsive variant="dark">
                    <thead>
                      <tr>
                        <td width="20%">Valor Solicitado</td>
                        <td width="20%">Valor Aprovado</td>
                        <td width="20%">Status</td>
                        <td width="20%">Finalidade</td>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionLoading ? (
                        <tr>
                          <td colSpan={4}>
                            <div className="contentcenter">
                              <Spinner animation="border" /> Carregando
                            </div>
                          </td>
                        </tr>
                      ) : transactions.length > 0 ? (
                        transactions.map((it, i) => (
                          <tr key={i}>
                            <td>{it.requestValueFormatted}</td>
                            <td>{it.approvedValueFormatted}</td>
                            <td>{it.statusValue}</td>
                            <td>{it.transactionTypeValue || "Não definido"}</td>
                          </tr>
                        ))
                      ) : (
                        <tr align="center">
                          <td colSpan={4}>Nenhuma transação</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      {/* Modal Resgatar Dinheiro */}
      <Modal
        size="sm"
        show={smShow}
        onHide={() => setSmShow(false)}
        className="mt-5"
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Resgatar dinheiro
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!!infoDonations && (
            <Form
              id="formRescue"
              initialData={{
                pageId: donationsId,
              }}
              onSubmit={handleSendRescue}
            >
              <h4>
                <b>Total Disponível:</b> {infoDonations.totalAvailableFormatted}
              </h4>
              <Input type="hidden" name="pageId" />
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3">
                    Finalidade
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Select
                  name="finalidade"
                  required
                  placeholder="Selecione..."
                  className="form-control"
                  options={finalities}
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3">Valor</InputGroup.Text>
                </InputGroup.Prepend>
                <CurrencyInput
                  placeholder="R$ 0.00"
                  className="form-control"
                  value={valueRequest}
                  onChange={({ target }) =>
                    setValueRequest(
                      Number(target.value.replace("R$ ", "").replace(/,/g, ""))
                    )
                  }
                  name="requestValue"
                  type="text"
                />
              </InputGroup>
              <Button block type="submit">
                Solicitar resgate
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>

      {/* Modal Criar Página */}
      <Modal
        className="mt-5"
        size="lg"
        show={modalCreatePage}
        onHide={() => setModalCreatePage(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Criar Página
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col>
              <Card bg="light" className="text-center mb-4 bshadow">
                <Card.Header>
                  <b>Nova página</b>
                </Card.Header>
                <Card.Body className="py-4">
                  {campaigns.length === 0 ? (
                    <div className="contentcenter">
                      Nenhuma campanha disponível
                    </div>
                  ) : (
                    <Form
                      initialData={{
                        pageDescription: pageDefault.pageDefaultDescription,
                        text: pageDefault.pageDefaultText,
                      }}
                      onSubmit={handleCreatePage}
                    >
                      <Row>
                        <Col xs={12} sm={6}>
                          <label className="switchBox mb-0">
                            <span>Página privada</span>
                            <Switch
                              onChange={setIsPrivateCreate}
                              checked={isPrivateCreate}
                            />
                          </label>
                          <br />
                          <label className="switchBox mb-3">
                            <span>Publicar</span>
                            <Switch
                              onChange={setIsPublishCreate}
                              checked={isPublishCreate}
                            />
                          </label>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={6}>
                          <div className="form-group">
                            <label htmlFor="fieldCampaign">Campanha</label>
                            <Select
                              name="campaignId"
                              id="fieldCampaign"
                              required
                              placeholder="Selecione..."
                              className="form-control"
                              onChange={changeCampaign}
                              options={campaigns}
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={6}>
                          <div className="form-group">
                            <label htmlFor="fieldYoutubeLink">YouTube</label>
                            <Input
                              name="videoUrl"
                              id="fieldYoutubeLink"
                              placeholder="https://www.youtube.com/watch?v=......."
                              className="form-control"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12}>
                          <div className="form-group">
                            <label htmlFor="fieldDescriptionPage">
                              Descrição da Página
                            </label>
                            <Input
                              name="pageDescription"
                              id="fieldDescriptionPage"
                              required
                              maxLength="50"
                              className="form-control"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="form-group">
                            <label htmlFor="fieldTextStudent">
                              Seu texto personalizado
                            </label>
                            <Input
                              multiline
                              name="text"
                              required
                              id="fieldTextStudent"
                              className="form-control"
                              rows="6"
                              maxLength="499"
                            />
                          </div>
                        </Col>
                      </Row>
                      <p className="text-muted">
                        * Esta página será enviada para aprovação do moderador
                      </p>
                      <Button
                        variant="primary"
                        type="submit"
                        block
                        disabled={modalLoading}
                      >
                        {modalLoading ? (
                          <Spinner animation="border" />
                        ) : (
                          `Criar página`
                        )}
                      </Button>
                    </Form>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Modal Atualizar Página */}
      <Modal
        className="mt-5"
        size="lg"
        show={modalUpdate}
        onHide={() => setModalUpdate(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Editar Página
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col>
              <Card bg="light" className="text-center mb-4 bshadow">
                <Card.Header>
                  <b>Editar página</b>
                </Card.Header>
                <Card.Body className="py-4">
                  {!!modalLoading && modalUpdateInfo == null ? (
                    <Row>
                      <Col xs={12} sm={12}>
                        <div className="contentcenter">
                          <Spinner animation="border" /> Carregando
                        </div>
                      </Col>
                    </Row>
                  ) : modalUpdateInfo != null ? (
                    <>
                      {!!updatePending && !updatePending.viewed && (
                        <Alert
                          variant={`info`}
                          className="text-left"
                          onClose={() => dismissAlert(modalUpdateInfo.pageId)}
                          dismissible
                        >
                          <b>Status da atualização: </b>
                          {
                            enumState.find((x) => x.id === updatePending.status)
                              .title
                          }
                          <br />
                          {!!updatePending.moderationMessage && (
                            <>
                              <b>Mensagem do Moderador: </b>
                              {updatePending.moderationMessage || ""}
                              <br />
                            </>
                          )}
                          <b>Código de atualização: </b>
                          {updatePending.updateVerificationCode || ""}
                        </Alert>
                      )}

                      {/* Status Pagina */}
                      <Row>
                        <Col xs={12} sm={6}>
                          <label className="switchBox mb-0">
                            <span>Página privada</span>
                            <Switch
                              onChange={(status) =>
                                handleUpdatePrivatePage(
                                  status,
                                  modalUpdateInfo.pageId
                                )
                              }
                              checked={isPrivateEdit}
                            />
                          </label>
                          <br />
                          <label className="switchBox mb-3">
                            <span>Publicar</span>
                            <Switch
                              onChange={(status) =>
                                handleUpdatePublishPage(
                                  status,
                                  modalUpdateInfo.pageId
                                )
                              }
                              checked={isPublishEdit}
                            />
                          </label>
                        </Col>
                        <Col xs={12} md={6}>
                          <label>
                            <span className="text-break">
                              {!!modalUpdateInfo.url
                                ? modalUpdateInfo.urlFormatted
                                : "Url não definida"}
                            </span>
                            <br />
                            <br />
                            <Button
                              onClick={() =>
                                handleGenerateNewUrl(modalUpdateInfo.pageId)
                              }
                            >
                              Gerar novo link
                            </Button>
                          </label>
                        </Col>
                      </Row>
                      <hr />

                      {/* Pendente e Reprovado */}
                      {!!updatePending &&
                        (updatePending.status === 10 ||
                          updatePending.status === 30) ? (
                        <>
                          <Form
                            initialData={updatePending}
                            onSubmit={handleCancelUpdate}
                          >
                            <Input name="donationPageId" type="hidden" />
                            <Row>
                              <Col xs={12} sm={6}>
                                <div className="form-group">
                                  <label htmlFor="fieldYoutubeLink">
                                    YouTube
                                  </label>
                                  <Input
                                    name="videoUrl"
                                    id="fieldYoutubeLink"
                                    disabled
                                    placeholder=""
                                    className="form-control"
                                  />
                                </div>
                              </Col>
                              <Col xs={12} sm={6}>
                                <div className="form-group">
                                  <label htmlFor="fieldDescriptionPage">
                                    Descrição da Página
                                  </label>
                                  <Input
                                    name="pageDescription"
                                    disabled
                                    id="fieldDescriptionPage"
                                    className="form-control"
                                    maxLength="50"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="form-group">
                              <label htmlFor="fieldTextStudent">
                                Seu texto personalizado
                              </label>
                              <Input
                                multiline
                                name="text"
                                disabled
                                id="fieldTextStudent"
                                className="form-control"
                                rows="6"
                                maxLength="499"
                              />
                            </div>

                            {updatePending.status === 10 && (
                              <Row>
                                <Col>
                                  <Button
                                    variant="danger"
                                    type="submit"
                                    block
                                    disabled={modalLoading}
                                    onClick={() => setStatusCancel(10)}
                                  >
                                    {modalLoading ? (
                                      <Spinner animation="border" />
                                    ) : (
                                      `Cancelar Atualização Pendente`
                                    )}
                                  </Button>
                                </Col>
                              </Row>
                            )}

                            {updatePending.status === 30 && (
                              <Row>
                                <Col>
                                  <Button
                                    variant="danger"
                                    type="submit"
                                    block
                                    disabled={modalLoading}
                                    onClick={() => setStatusCancel(10)}
                                  >
                                    {modalLoading ? (
                                      <Spinner animation="border" />
                                    ) : (
                                      `Cancelar edição`
                                    )}
                                  </Button>
                                </Col>
                                <Col>
                                  <Button
                                    variant="warning"
                                    type="submit"
                                    block
                                    disabled={modalLoading}
                                    onClick={() => setStatusCancel(20)}
                                  >
                                    {modalLoading ? (
                                      <Spinner animation="border" />
                                    ) : (
                                      `Editar novamente`
                                    )}
                                  </Button>
                                </Col>
                              </Row>
                            )}
                          </Form>
                        </>
                      ) : (
                        <Form
                          initialData={modalUpdateInfo}
                          onSubmit={handleUpdatePage}
                        >
                          <Input name="pageId" type="hidden" />

                          <Row>
                            <Col xs={12} sm={6}>
                              <div className="form-group">
                                <label htmlFor="fieldYoutubeLink">
                                  YouTube
                                </label>
                                <Input
                                  name="videoUrl"
                                  id="fieldYoutubeLink"
                                  placeholder="https://www.youtube.com/watch?v=......."
                                  className="form-control"
                                />
                              </div>
                            </Col>
                            <Col xs={12} sm={6}>
                              <div className="form-group">
                                <label htmlFor="fieldDescriptionPage">
                                  Descrição da Página
                                </label>
                                <Input
                                  name="pageDescription"
                                  required
                                  id="fieldDescriptionPage"
                                  className="form-control"
                                  maxLength="50"
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="form-group">
                            <label htmlFor="fieldTextStudent">
                              Seu texto personalizado
                            </label>
                            <Input
                              multiline
                              name="text"
                              required
                              id="fieldTextStudent"
                              className="form-control"
                              rows="6"
                              maxLength="499"
                            />
                          </div>
                          <p className="text-muted">
                            * As mudanças serão enviadas para aprovação do
                            moderador
                          </p>
                          <Button
                            variant="primary"
                            type="submit"
                            block
                            disabled={modalLoading}
                          >
                            {modalLoading ? (
                              <Spinner animation="border" />
                            ) : (
                              `Salvar`
                            )}
                          </Button>
                        </Form>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </Wrapper>
  );
}
