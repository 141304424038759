import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";

import AllDonations from "~/pages/AllDonationsPage";
import StudentDonation from "~/pages/StudentDonationPage";
import DonationsCampaign from "~/pages/DonationsCampaign";
import SignIn from "~/pages/SignIn";
import Logout from "~/pages/Logout";
import SignUp from "~/pages/SignUp";
import Recovery from "~/pages/RecoveryPass";
import ConfirmPass from "~/pages/RecoveryPass/ConfirmPass";
import Dashboard from "~/pages/Dashboard";
import ConfirmPage from "~/pages/Dashboard/ConfirmPage";

import AboutProject from "~/pages/LandingPages/AboutProject";

import HomePage from "~/pages/AdminPages/Home";
import ContasFinanceiras from "~/pages/AdminPages/ContasFinanceiras";
import TransferenciaPage from "~/pages/AdminPages/TransferenciasFinanceiras";
import TransferenciasRealizadas from "~/pages/AdminPages/TransferenciasRealizadas";
import ModerarAlteracoes from "~/pages/AdminPages/ModerarAlteracoes";
import Campanhas from "~/pages/AdminPages/Campanhas";
import Lotes from "~/pages/AdminPages/Lotes";
import Configuracoes from "~/pages/AdminPages/Configuracoes";
import Doacoes from "~/pages/AdminPages/Doacoes";

import AuthLayout from "~/pages/__layouts/auth";
import DefaultLayout from "~/pages/__layouts/default";
import AdminLayout from "~/pages/__layouts/admin";
import UserManagement from "~/pages/AdminPages/userManagement";
import Students from "~/pages/AdminPages/Students";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={AllDonations} layout={DefaultLayout} />
      <Route
        path="/sobre/:scroll"
        exact
        component={AboutProject}
        layout={DefaultLayout}
      />
      <Route
        isPrivate
        path="/student/:slug"
        component={StudentDonation}
        layout={DefaultLayout}
      />
      <Route
        path="/campaign/:campaignId"
        component={DonationsCampaign}
        layout={DefaultLayout}
      />
      <Route
        path="/authentication/emailconfirmation"
        exact
        component={ConfirmPage}
        layout={AuthLayout}
      />
      <Route
        path="/admin/home"
        exact
        isPrivate
        component={HomePage}
        layout={AdminLayout}
      />
      <Route
        path="/admin/financial-account"
        exact
        isPrivate
        component={ContasFinanceiras}
        layout={AdminLayout}
      />
      <Route
        path="/admin/transactions"
        exact
        isPrivate
        component={TransferenciaPage}
        layout={AdminLayout}
      />
      <Route
        path="/admin/allotment"
        exact
        isPrivate
        component={Lotes}
        layout={AdminLayout}
      />
      <Route
        path="/admin/:type/transactions-done/:id"
        exact
        isPrivate
        component={TransferenciasRealizadas}
        layout={AdminLayout}
      />
      <Route
        path="/admin/campaign"
        exact
        isPrivate
        component={Campanhas}
        layout={AdminLayout}
      />
      <Route
        path="/admin/requests"
        exact
        isPrivate
        component={ModerarAlteracoes}
        layout={AdminLayout}
      />
      <Route
        path="/admin/donations"
        exact
        isPrivate
        component={Doacoes}
        layout={AdminLayout}
      />
      <Route
        path="/admin/settings"
        exact
        isPrivate
        component={Configuracoes}
        layout={AdminLayout}
      />
      <Route
        path="/admin/usermanagement"
        exact
        isPrivate
        component={UserManagement}
        layout={AdminLayout}
      />
      <Route
        path="/admin/students"
        exact
        isPrivate
        component={Students}
        layout={AdminLayout}
      />
      <Route path="/login" exact component={SignIn} layout={AuthLayout} />
      <Route path="/logout" exact component={Logout} layout={AuthLayout} />
      <Route path="/register" component={SignUp} layout={AuthLayout} />
      <Route path="/recovery" component={Recovery} layout={AuthLayout} />
      <Route
        path="/authentication/confirmpasswordrecover"
        component={ConfirmPass}
        layout={AuthLayout}
      />
      <Route
        path="/dashboard"
        exact
        component={Dashboard}
        isPrivate
        layout={DefaultLayout}
      />
      {/* <Route
        path="/profile"
        component={Profile}
        isPrivate
        layout={DefaultLayout}
      /> */}
    </Switch>
  );
}
