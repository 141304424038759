import React, { useRef, useEffect, useState, useMemo } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import api from "~/services/api";
import urlsystem from "~/config/URLSystem";
import { formatPrice } from "~/util/format";
import buildURLQuery from "~/util/buildURLQuery";

import history from "~/services/history";

import Switch from "react-switch";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { Form, Input, Select } from "@rocketseat/unform";
import {
  Table,
  Container,
  Row,
  Col,
  Dropdown,
  Spinner,
  Button,
  Modal,
  Card,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { toast } from "react-toastify";

import PaginationComponent from "react-js-pagination";

// import { Container } from './styles';
import { IoMdMenu, IoMdSearch } from "react-icons/io";
import { MdUpdate, MdClear } from "react-icons/md";
import { FaFilter } from "react-icons/fa";
import enumTransactionType from "~/util/enumTransactionType";
import enumTransactionStatus from "~/util/enumTransactionStatus";
import { GiHandOfGod } from "react-icons/gi";

export default function TransferenciasRealizadas() {
  const { type, id } = useParams();
  //let location = useLocation();
  const tenantProfile = useSelector((state) => state.header.tenantProfile);

  const viewByCampaign = useMemo(() => {
    /* const search = new URLSearchParams(location.search);
    const view = search.get("mode"); */
    return type === "campaign" ? true : false;
  });

  const searchRef = useRef(null);
  const [searching, setSearching] = useState(false);
  const [searchString, setSearchString] = useState("");

  const [filtring, setFiltring] = useState(false);
  const [finalityId, setFinalityId] = useState("");
  const [itemsFinality, setItemsFinality] = useState([]);

  const [timeout, setTimeOut] = useState(0);

  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  const [activePage, setActivePage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(0);

  const [updatePending, setUpdatePending] = useState({});
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  // change new tenant
  useEffect(() => {
    setActivePage(1);
  }, [tenantProfile, searchString, finalityId]);

  // change itens finality
  useEffect(() => {
    const loadData = async () => {
      if (viewByCampaign) {
        try {
          const response = await api.get(
            `/AdminArea/GetTenantFinancialAccounts/${tenantProfile}/1/1000`
          );
          const dataDonations = response.data;
          setItemsFinality(
            dataDonations.entities.map((x) => ({
              value: x.financialAccountId,
              id: x.financialAccountId,
              title: x.alias,
            }))
          );
        } catch (error) {
          if (!!error.response && error.response.status !== 401) {
            toast.error("Erro ao carregar os itens do filtro");
          }
        } finally {
        }
      }
    };
    loadData();
  }, [tenantProfile, viewByCampaign]);

  /* Load data pages */
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);

        const urlBase = viewByCampaign
          ? "GetTransactionsByCampaign"
          : "GetFinancialAccountTransactions";

        const response = await api.get(
          `/adminarea/${urlBase}/${tenantProfile}/${id}/${activePage}/${itemPerPage}?${buildURLQuery(
            { search: searchString, redeemfinalityid: finalityId }
          )}`
        );

        const dataDonations = response.data;

        setTotalItens(dataDonations.indexSize);
        setPages(
          dataDonations.entities.map((x) => ({
            ...x,
            dateFormatted: x.transactionDate
              ? format(new Date(x.transactionDate), "dd/MMM/yyyy HH:mm", {
                locale: pt,
              })
              : null,
            valueFormatted: formatPrice(x.transactionValue),
            accounted: x.accounted,
          })),
        );
      } catch (error) {
        if (!!error.response && error.response.status !== 401) {
          toast.error("Erro ao carregar a página");
        }
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [
    activePage,
    setItemPerPage,
    tenantProfile,
    searchString,
    finalityId,
    viewByCampaign,
  ]);

  useEffect(() => {
    if (searching) {
      searchRef.current.focus();
    }
  }, [searching]);

  const handleViewRequest = (data) => {
    toast.info("Não implementado");
  };

  const handleFind = (e) => {
    const vl = e.target.value;

    if (timeout) clearTimeout(timeout);
    setTimeOut(
      setTimeout(() => {
        setSearchString(vl);
      }, 400)
    );
  };

  const handleClear = () => {
    setSearchString("");
    setSearching(false);
  };

  const handleSearch = () => {
    setSearching(true);
  };

  const handleChangeFilter = (e) => {
    const vl = e.target.value;
    setFinalityId(vl);
  };

  const handleFilter = () => {
    setFiltring(true);
  };

  const handleFilterClear = () => {
    setFiltring(false);
    setFinalityId("");
  };

  const searchFunction = (data) => { };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">
          <Link
            to={viewByCampaign ? "/admin/campaign" : "/admin/financial-account"}
          >
            {viewByCampaign ? `Campanhas` : `Contas Financeiras`}
          </Link>{" "}
          / Transferências Realizadas
        </h1>
      </div>

      {/* Search */}
      {/* <Row className="d-flex justify-content-center">
        <Col xs={11} sm={12} className="d-flex justify-content-end mb-2">
          {!!searching ? (
            <Form onSubmit={searchFunction}>
              <InputGroup className="">
                <FormControl
                  type="text"
                  placeholder="Filtrar por aluno"
                  className="form-control inputSearch"
                  onChange={handleFind}
                  ref={searchRef}
                />
                <InputGroup.Append>
                  <Button variant="outline-danger" onClick={handleClear}>
                    <MdClear />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          ) :
            (
              <Button
                variant="outline-info"
                onClick={handleSearch}
                title="Clique para procurar"
              >
                <IoMdSearch />
              </Button>
            )}
          {type === "campaign" && (
            <div className="ml-2">
              {filtring ? (
                <Form onSubmit={searchFunction}>
                  <InputGroup className="">
                    <Select
                      name="filtragem"
                      placeholder="Filtrar por Conta"
                      className="form-control"
                      options={itemsFinality}
                      onChange={handleChangeFilter}
                    />
                    <InputGroup.Append>
                      <Button
                        variant="outline-danger"
                        onClick={handleFilterClear}
                      >
                        <MdClear />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form>
              ) : (
                  <Button
                    variant="outline-secondary"
                    onClick={handleFilter}
                    title="Clique para filtrar"
                  >
                    <FaFilter />
                  </Button>
                )}
            </div>
          )}
        </Col>
      </Row> */}

      {/* List */}
      <Row className="d-flex justify-content-center">
        <Col xs={11} sm={12}>
          <div className="table-responsive pt-3">
            <Table responsive striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Código da transação</th>
                  <th className="m-0">
                    Aluno
                        {!!searching ? (
                      <Form onSubmit={searchFunction}>
                        <InputGroup className="">
                          <FormControl
                            type="text"
                            placeholder="Filtrar por aluno"
                            className="form-control inputSearch"
                            onChange={handleFind}
                            ref={searchRef}
                          />
                          <InputGroup.Append>
                            <Button variant="outline-danger" onClick={handleClear}>
                              <MdClear />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Form>
                    ) :
                      (
                         <IoMdSearch style={{width:"10%", height:"120%", color:"#007bff"}} className="float-right"
                            variant="outline-secondary"
                            onClick={handleSearch}
                            title="Clique para procurar"/>
                        // <Button
                        //   className="float-right"
                        //   variant="outline-info"
                        //   onClick={handleSearch}
                        //   title="Clique para procurar"
                        // >
                        //   <IoMdSearch />
                        // </Button>
                      )}
                    {type === "campaign" && (
                      <div className="ml-2">
                        {filtring ? (
                          <Form onSubmit={searchFunction}>
                            <InputGroup className="">
                              <Select
                                name="filtragem"
                                placeholder="Filtrar por Conta"
                                className="form-control"
                                options={itemsFinality}
                                onChange={handleChangeFilter}
                              />
                              <InputGroup.Append>
                                <Button
                                  variant="outline-danger"
                                  onClick={handleFilterClear}
                                >
                                  <MdClear />
                                </Button>
                              </InputGroup.Append>
                            </InputGroup>
                          </Form>
                        ) : (
                            <Button
                              variant="outline-secondary"
                              onClick={handleFilter}
                              title="Clique para filtrar"
                            >
                              <FaFilter />
                            </Button>
                          )}
                      </div>
                    )}
                  </th>
                  <th>Contabilizado</th>
                  <th>Campanha</th>
                  <th>Valor</th>
                  <th>Moderado por</th>
                  <th>Data</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={7}>
                      <div className="contentcenter">
                        <Spinner animation="border" /> Carregando
                      </div>
                    </td>
                  </tr>
                ) : pages.length === 0 ? (
                  <tr>
                    <td colSpan={7} className="text-center">
                      Nenhum registro
                    </td>
                  </tr>
                ) : (
                      console.log(pages),

                      pages.map((item, index) => (
                        <tr key={item.transactionRequestId}>
                          <td>{item.transactionRequestId}</td>
                          <td>{item.originStudentName}</td>
                          {
                            item.accounted ?
                              <td>Sim</td> :
                              <td>Não</td>
                          }
                          <td>{item.originPageCampaignName}</td>
                          <td>{item.valueFormatted}</td>
                          <td>{item.moderatorName}</td>
                          <td>{item.dateFormatted}</td>
                          <td className="tdControl">
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                              >
                                <IoMdMenu size={22} />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => handleViewRequest(item)}
                                >
                                  <MdUpdate size={18} color="#17a2b8" /> Visualizar
                              alteração
                            </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    )}
              </tbody>
            </Table>
            <Container hidden={loading || pages.length === 0 ? true : false}>
              <Row
                className={`justify-content-center ${
                  totalItens <= itemPerPage ? "d-none" : "d-flex"
                  }`}
              >
                <Col xs={10} sm={5} className="d-flex justify-content-center">
                  <PaginationComponent
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination mb-0"
                    activePage={activePage}
                    itemsCountPerPage={itemPerPage}
                    totalItemsCount={totalItens}
                    pageRangeDisplayed={5}
                    onChange={(e) => setActivePage(e)}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </>
  );
}
