import React from "react";
import { Link } from "react-router-dom";

import imagePreLoading from "~/assets/imagePreLoading.png";
import { FaDonate, FaUserAlt } from "react-icons/fa";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { Wrapper } from "./styles";

import { Card, Col } from "react-bootstrap";

function CardStudentPage({ data }) {
  return (
    <Wrapper className="mb-4 col-lg-3 col-md-4 col-sm-6 col-10">
      <Card className="h-100">
        <div className="divImage">
          {!!data.imageId ? (
            <>
              <img src={imagePreLoading} id="preloadimage" />
              <LazyLoadImage
                effect="blur"
                src={data.urlImage}
                visibleByDefault={data.src === imagePreLoading}
                className="imagePeople text-center"
              />
            </>
          ) : (
            <FaUserAlt size={200} color="#cccccc" />
          )}
        </div>
        <Card.Body>
          <Card.Title>{data.studentName}</Card.Title>
          <Card.Text>{data.textSplit}</Card.Text>
          <hr className="mt-auto" />
          <div className="d-flex justify-content-end">
            <Link
              to={`/student/${data.pageUrl}`}
              className="btn btn-warning btn-block"
            >
              Apoiar <FaDonate size={18} />
            </Link>
          </div>
        </Card.Body>
      </Card>
    </Wrapper>
  );
}

export default CardStudentPage;
