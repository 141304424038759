import React, { useEffect, useRef, useState, useMemo } from "react";
import api from "~/services/api";
import buildURLQuery from "~/util/buildURLQuery";
import { Form, Input, Select } from "@rocketseat/unform";
import {
  Table,
  Container,
  Row,
  Col,
  Dropdown,
  Spinner,
  Button,
  Modal,
  Card,
  InputGroup,
  FormControl,
  Jumbotron,
  ButtonGroup,
  ToggleButton,
  Alert
} from "react-bootstrap";
import { toast } from "react-toastify";

import PaginationComponent from "react-js-pagination";
import { IoMdMenu, IoMdSearch } from "react-icons/io";
import * as MD from "react-icons/md";
import * as RI from "react-icons/ri";
import { UnformInput } from "~/components/Form";


export default function Students() {
  const searchRef = useRef(null);

  // pagination
  const [lastParams, setLastParams] = useState({ activePage: 1, itemPerPage: 10, searchString: "" });
  const [pages, setPages] = useState([]);
  const [totalItens, setTotalItens] = useState(0);
  const [timeout, setTimeOut] = useState(0);

  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);

  // modal
  const [modalEditStudent, setModalEditStudent] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState({});

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async (activePage = null, itemPerPage = null, searchString = null) => {
    try {
      setLoading(true);

      const params = {
        activePage: activePage ? activePage : lastParams.activePage,
        itemPerPage: itemPerPage ? itemPerPage : lastParams.itemPerPage,
        searchString: searchString != null ? searchString : lastParams.searchString
      }

      const response = await api.get(
        `/adminarea/GetAllStudent/${params.activePage}/${params.itemPerPage}?${buildURLQuery(
          { search: params.searchString }
        )}`
      );
      setLastParams(params)
      const dataUsers = response.data;
      setTotalItens(dataUsers.indexSize);
      setPages(
        dataUsers.entities.map((x) => ({
          ...x
        }))
      );
    } catch (error) {
      if (!!error.response && error.response.status !== 401) {
        toast.error("Erro ao carregar a página");
      }
    } finally {
      setLoading(false);
    }
  };
  const handleFind = (e) => {
    const vl = e.target.value;

    if (timeout) clearTimeout(timeout);
    setTimeOut(
      setTimeout(() => {
        loadData(null, null, vl)
      }, 400)
    );
  };
  const handleClear = () => {
    setSearching(false);
    loadData(null, null, "")
  };
  const handleSearch = () => {
    setSearching(true);
  };

  async function UpdateStudent(_data) {
    const response = await api.put(`/adminArea/PutStudent`, _data, { params: { oldUserEmail: selectedStudent.email } });
    const { data } = response;

    if (data === "Informações alteradas") {
      toast.success(data);
      setModalEditStudent(false);
      setSelectedStudent({})
    } else {
      toast.warning(data);
    }
  }


  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Gerenciamento de Alunos</h1>
      </div>

      <Row className="d-flex justify-content-center">
        <Col xs={12} sm={12} className="d-flex justify-content-end">
          <Form
            initialData={{ itensPerPage: lastParams.itemPerPage }}
            className="mr-2"
          >
            <InputGroup className="">
              <Select
                name="itensPerPage"
                placeholder="Items por página"
                className="form-control"
                options={[
                  {
                    value: 10,
                    id: 10,
                    title: 10,
                  },
                  {
                    value: 25,
                    id: 25,
                    title: 25,
                  },
                  {
                    value: 50,
                    id: 50,
                    title: 50,
                  },
                  {
                    value: 100,
                    id: 100,
                    title: 100,
                  },
                ]}
                onChange={({ target }) => loadData(null, target.value, null)}
              />
            </InputGroup>
          </Form>

          {!!searching ? (
            <Form >
              <InputGroup className="">
                <FormControl
                  type="text"
                  placeholder="Procurar aluno"
                  className="form-control inputSearch"
                  onChange={handleFind}
                  ref={searchRef}
                />
                <InputGroup.Append>
                  <Button variant="outline-danger" onClick={handleClear}>
                    <MD.MdClear />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          ) : (
            <Button
              variant="outline-info"
              onClick={handleSearch}
              title="Clique para procurar"
            >
              <IoMdSearch />
            </Button>
          )}
        </Col>
      </Row>

      {/* List */}
      <Row className="d-flex justify-content-center">
        <Col xs={11} sm={12}>
          <div className="table-responsive pt-3">
            <Table responsive striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>RA</th>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Curso</th>
                  <th>Campus</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={8}>
                      <div className="contentcenter">
                        <Spinner animation="border" /> Carregando
                      </div>
                    </td>
                  </tr>
                ) : pages.length === 0 ? (
                  <tr>
                    <td colSpan={8} className="text-center">
                      Nenhum registro
                    </td>
                  </tr>
                ) : (
                  pages.map((item, index) => (
                    <tr key={item.ra}>
                      <td>{item.ra}</td>
                      <td>{item.name}</td>
                      <td>{item.email}</td>
                      <td>{item.course}</td>
                      <td>{item.institution}</td>

                      <td className="tdControl">
                        <Dropdown drop="left">
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic"
                          >
                            <IoMdMenu size={22} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => { setModalEditStudent(true); setSelectedStudent(item) }}
                            >
                              <RI.RiEdit2Line size={"18"} color="#17a2b8" /> Editar
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            <Container hidden={loading || pages.length === 0 ? true : false}>
              <Row
                className={`justify-content-center ${totalItens <= lastParams.itemPerPage ? "d-none" : "d-flex"
                  }`}
              >
                <Col xs={10} sm={5} className="d-flex justify-content-center">
                  <PaginationComponent
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination mb-0"
                    activePage={lastParams.activePage}
                    itemsCountPerPage={lastParams.itemPerPage}
                    totalItemsCount={totalItens}
                    pageRangeDisplayed={5}
                    onChange={(e) => loadData(e)}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>

      <Modal size="md" show={modalEditStudent} onHide={() => { setModalEditStudent(false); setSelectedStudent({}) }} >
        <Modal.Header closeButton>
          <Modal.Title >
            <span className="align-middle">Informações do Aluno</span>
          </Modal.Title>

        </Modal.Header>
        <Form initialData={selectedStudent} onSubmit={UpdateStudent} >
          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <Col>
                <label style={{ fontWeight: "bold" }}>RA</label>
                <Input
                  name="ra"
                  required
                  className="form-control"
                  value={selectedStudent.ra}
                  autoFocus
                  disabled
                />
              </Col>

              <Col>
                <label style={{ fontWeight: "bold" }}>Nome</label>
                <Input
                  name="name"
                  required
                  className="form-control"
                  value={selectedStudent.name}
                  autoFocus
                  disabled
                />
              </Col>

              <Col>
                <label style={{ fontWeight: "bold" }}>E-mail</label>
                <UnformInput
                  name="email"
                  required
                  className="form-control"
                />
              </Col>

              <Col>
                <label style={{ fontWeight: "bold" }}>Curso</label>
                <UnformInput
                  name="course"
                  required
                  className="form-control"
                />
              </Col>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div style={{ display: "flex", width: "100%", gap: ".5rem" }}>
              <Button
                variant="danger"
                onClick={() => { setModalEditStudent(false); setSelectedStudent({}) }}
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: ".25rem"
                }}
              >
                <MD.MdClose />
                Cancelar
              </Button>

              <Button
                variant="primary"
                type="submit"
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: ".25rem"
                }}
              >
                <MD.MdUpdate />
                Atualizar
              </Button>
            </div>
          </Modal.Footer>
        </Form>
      </Modal >
    </>
  )
}