import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

import { useDispatch } from "react-redux";
import { signOut } from "~/store/modules/auth/actions";

/*import AuthLayout from "~/pages/__layouts/auth";
import DefaultLayout from "~/pages/__layouts/default";*/

import { store } from "~/store";
import { toast } from "react-toastify";

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  layout: Layout,
  ...rest
}) {
  const dispatch = useDispatch();
  const { signed, exp, user } = store.getState().auth;
  const now = Math.round(new Date().getTime() / 1000);
  if (signed && (exp < 1 || now >= exp)) {
    dispatch(signOut());
  }
  if (signed && isPrivate && !user.emailConfirmed) {
    toast.error("Verifique seu e-mail para continuar");
    return <Redirect to="/" />;
  }

  if (!signed && isPrivate) {
    toast.error("Faça login para acessar");

    return <Redirect to="/login" />;
  }

  /* if (signed && !isPrivate) {
    return <Redirect to="/" />;
  } */

  //const Layout = signed ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
