export function updateProfileRequest(data) {
  return {
    type: "@user/UPDATE_PROFILE_REQUEST",
    payload: { data },
  };
}
export function updateProfileSuccess(profile) {
  return {
    type: "@user/UPDATE_PROFILE_SUCCESS",
    payload: { profile },
  };
}
export function updateImageProfile(avatar) {
  return {
    type: "@user/UPDATE_IMAGE_PROFILE",
    payload: { avatar },
  };
}
export function updateProfileFailure() {
  return {
    type: "@user/UPDATE_PROFILE_FAILURE",
  };
}
export function acceptRegulationRequest(template) {
  return {
    type: "@user/UPDATE_ACCEPT_REGULATION_REQUEST",
    payload: { template },
  };
}
export function acceptRegulationSuccess(status) {
  return {
    type: "@user/UPDATE_ACCEPT_REGULATION_SUCCESS",
    payload: { status },
  };
}

export function updateEmailConfirmation(Confirmation) {
  return {
    type: "@user/UPDATE_EMAIL_CONFIRMATION",
    payload: { Confirmation },
  };
}
