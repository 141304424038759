import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import history from "~/services/history";

import Header from "~/components/Header";
import { Wrapper, Footer } from "./styles";

import { Container } from "react-bootstrap";

export default function DefaultLayout({ children }) {
  /* middelware */
  let location = useLocation();
  const template = useSelector((state) => state.user.template);
  const tenantsAllowed = ["donor", "student"];
  const root = "dashboard";

  useEffect(() => {
    if (
      location.pathname.includes(root) &&
      tenantsAllowed.indexOf(template) === -1
    ) {
      history.push("/");
    }
  }, [location]);
  /* end middelware */

  return (
    <Wrapper className="d-flex flex-column h-100">
      <Header />
      {children}
      <Footer className="mt-auto py-3">
        <Container>
          DevTeam <strong>[UNASP]</strong>
        </Container>
      </Footer>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
