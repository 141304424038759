import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Row,
  Col,
  Button,
  Accordion,
  Badge,
  Card,
  Alert,
  Container,
  Image,
  InputGroup,
  Modal,
  FormControl,
} from "react-bootstrap";

import api from "~/services/api";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import img_01 from "~/assets/img_01.jpg";
import img_02 from "~/assets/img_02.jpg";
import img_03 from "~/assets/img_03.jpg";
import img_04 from "~/assets/img_04.jpg";

import { Wrapper } from "./styles";

import { FaRegFileAlt, FaRegClock } from "react-icons/fa";

export default function AboutProject() {
  const { scroll } = useParams();

  const [currentPosition, setCurrentPosition] = useState(0);

  const [modalTermUse, setModalTermUse] = useState(false);
  const [termoDeUso, setTermoDeUso] = useState({
    text: "",
    title: "",
    date: "",
  });

  const oprograma = useRef(null);
  const ounasp = useRef(null);

  useEffect(() => {
    loadTermPublic();
  }, []);

  const loadTermPublic = async () => {
    const response = await api.get(`/public/getGeneralRegulation/`);
    const { creationDate, text, title } = response.data;
    setTermoDeUso({
      text,
      title,
      date: creationDate
        ? format(new Date(creationDate), "dd/MM/yyyy HH:mm", {
            locale: pt,
          })
        : null,
    });
  };

  useEffect(() => {
    switch (scroll) {
      case "oprograma":
        scrollToRef(oprograma);
        break;
      case "ounasp":
        scrollToRef(ounasp);
        break;
      default:
        scrollToRef(oprograma);
        break;
    }
  }, [scroll]);

  const scrollToRef = (ref) => {
    const toPosition = ref.current.offsetTop;
    const timeInterval = 0.05;

    let i = currentPosition;
    setCurrentPosition(toPosition);

    if (currentPosition < toPosition) {
      let int = setInterval(function () {
        window.scrollTo(currentPosition, i);
        i += 10;
        if (i >= toPosition) {
          clearInterval(int);
          setCurrentPosition(i);
        }
      }, timeInterval);
    } else {
      let int = setInterval(function () {
        window.scrollTo(currentPosition, i);
        i -= 10;
        if (i <= toPosition) {
          clearInterval(int);
          setCurrentPosition(i);
        }
      }, timeInterval);
    }
  };

  return (
    <>
      <Wrapper className="py-2">
        <section className="showcase">
          <div className="container-fluid p-0">
            <div className="row no-gutters style1" ref={oprograma}>
              <div
                className="col-lg-6 order-lg-2 text-white showcase-img"
                style={{
                  backgroundImage: `url('${img_01}')`,
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="col-lg-6 order-lg-1 my-auto showcase-text">
                <h2>Sobre o Programa</h2>
                <p className="lead mb-0">
                  A formação acadêmica é um ponto extremamente importante na
                  vida de um jovem, por isso, mesmo com as dificuldades impostas
                  pela pandemia do novo coronavírus, nossos alunos não poderiam
                  deixar de estudar. Preocupado com sua educação e
                  profissionalização, o Centro Universitário Adventista de São
                  Paulo (Unasp), está fazendo todo o possível para manter o
                  nível de excelência nos serviços prestados aos estudantes.
                </p>
              </div>
            </div>
            <div className="row no-gutters style2">
              <div
                className="col-lg-6 text-white showcase-img"
                style={{
                  backgroundImage: `url('${img_02}')`,
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="col-lg-6 my-auto showcase-text">
                <p className="lead mb-0">
                  Pensando nisso, foi criado o projeto
                  <span className="font-weight-bold"> #EuApoio</span>. Esta
                  iniciativa tem por objetivo auxiliar os alunos de modo que
                  continuem com seus estudos, sem maiores imprevistos ou
                  prejuízos. Em tempos difíceis e complexos como os que estamos
                  vivendo, nossas maiores ferramentas são a educação e a
                  solidariedade. Sendo assim, por meio de doações, anônimas ou
                  não, você irá contribuir para a formação dos futuros
                  profissionais do nosso país. Esse projeto busca manter nossos
                  estudantes em um caminho íntegro e que os leve para um futuro
                  promissor. Com a sua colaboração, poderemos entregar
                  profissionais que vão além do exercício prático da profissão,
                  capazes de contribuir com a construção de uma sociedade
                  melhor. Entendemos que os sonhos não podem ser deixados de
                  lado, e contamos com sua ajuda, para conseguir realizar os
                  sonhos de todos os alunos que aguardam por uma oportunidade
                  para continuar.
                </p>
              </div>
            </div>
            <div className="row no-gutters style1" ref={ounasp}>
              <div
                className="col-lg-6 order-lg-2 text-white showcase-img"
                style={{
                  backgroundImage: `url('${img_03}')`,
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="col-lg-6 order-lg-1 my-auto showcase-text">
                <h2>Sobre o UNASP</h2>
                <p className="lead mb-0">
                  O Centro Universitário Adventista de São Paulo (Unasp), é uma
                  instituição centenária que cresce em estrutura e padrão de
                  educação, conceituada com nota máxima no MEC. A cada ano
                  alcançamos nosso principal objetivo: formar cidadãos de
                  caráter que façam a diferença em sua sociedade e no mundo.
                </p>
              </div>
            </div>
            <div className="row no-gutters style2">
              <div
                className="col-lg-6 text-white showcase-img"
                style={{
                  backgroundImage: `url('${img_04}')`,
                  backgroundPosition: "center",
                }}
              ></div>
              <div className="col-lg-6 my-auto showcase-text">
                <p className="lead mb-0">
                  Somos quatro campi e juntos somamos mais de 17 mil alunos,
                  cursando da educação infantil à faculdade. Oferecemos mais de
                  30 cursos de graduação, alguns deles com opção de residência
                  no campus. Para aqueles que optam por viverem em nossos
                  internatos, proporcionamos um estilo de vida saudável e
                  equilibrado, focado no desenvolvimento pessoal e profissional
                  de cada aluno.
                </p>
                <p className="lead text-right mt-4">
                  <Button
                    href="https://unasp.br"
                    target="_blank"
                    variant="danger"
                    style={{ borderRadius: "40px" }}
                  >
                    Clique para saber mais
                  </Button>
                </p>
              </div>
            </div>
            <div className="row no-gutters style3 text-center py-5">
              <div className="col-lg-12 text-white">
                <Button
                  style={{ borderRadius: "40px" }}
                  onClick={() => setModalTermUse(true)}
                >
                  Regulamento do programa <FaRegFileAlt />
                </Button>
              </div>
            </div>
          </div>
        </section>
      </Wrapper>

      {/* Modal Termo de Uso */}
      <Modal
        className="mt-5"
        size="lg"
        show={modalTermUse}
        onHide={() => setModalTermUse(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {termoDeUso.title}{" "}
            <Badge variant="secondary">
              <FaRegClock />
              {` ${termoDeUso.date}`}
            </Badge>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card bg="white" text="dark">
            <Card.Body>
              <Card.Text
                className="h6 text-justify"
                dangerouslySetInnerHTML={{
                  __html: termoDeUso.text,
                }}
              ></Card.Text>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
}
