import React, { useRef, useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import api from "~/services/api";
import urlsystem from "~/config/URLSystem";
import buildURLQuery from "~/util/buildURLQuery";
import { formatPrice } from "~/util/format";

import CurrencyInput from "~/util/CurrencyInput";

import history from "~/services/history";

import Switch from "react-switch";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";


import { Form, Input, Select } from "@rocketseat/unform";
import {
    Table,
    Container,
    Row,
    Col,
    Dropdown,
    Spinner,
    Button,
    Modal,
    Card,
    InputGroup,
    FormControl,
    Jumbotron,
    ButtonGroup,
    ToggleButton,
    Alert
} from "react-bootstrap";
import { toast } from "react-toastify";

import PaginationComponent from "react-js-pagination";

// import { Container } from './styles';
import { IoMdAdd, IoMdMenu, IoMdSearch } from "react-icons/io";
import * as MD from "react-icons/md";
import * as RI from "react-icons/ri";
import { FaTimes, FaCheck, FaFilter } from "react-icons/fa";

import enumTransactionType from "~/util/enumTransactionType";
import enumTransactionStatus from "~/util/enumTransactionStatus";
import { Radio } from "react-bootstrap";


export default function UserManagement() {
    const searchRef = useRef(null);
    const [searching, setSearching] = useState(false);
    const [searchString, setSearchString] = useState("");
    const [timeout, setTimeOut] = useState(0);

    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(true);


    const [totalItens, setTotalItens] = useState(0);

    const [lastParams, setLastParams] = useState({ activePage: 1, itemPerPage: 10, searchString: "" });
    const [updatePending, setUpdatePending] = useState({});
    const [modalUpdate, setModalUpdate] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [statusAprove, setStatusAprove] = useState(0);
    const [valueAprove, setValueAprove] = useState(0);
    const [availableRoles, setAvailableRoles] = useState({ loading: true, data: [] });
    const [userRoles, setUserRoles] = useState({ loading: true, data: [] });

    const [AllTenants, setAllTenants] = useState({ loading: true, data: [] });
    const [tenantChecked, setTenantChecked] = useState();
    const [modalUserPWD, setModalUserPWD] = useState({
        isOpen: false,
        user: {}
    });

    const [modalCreateUser, setModalCreateUser] = useState(false);
    const handleClose = () => setModalCreateUser(false);

    const handleFind = (e) => {
        const vl = e.target.value;

        if (timeout) clearTimeout(timeout);
        setTimeOut(
            setTimeout(() => {
                setSearchString(vl);
                loadData(null, null, vl)
            }, 400)
        );
    };

    const handleClear = () => {
        setSearchString("");
        setSearching(false);
        loadData(null, null, "")
    };

    const handleViewRequest = async (data) => {
        setUpdatePending(data);

        setValueAprove(data.requestValue);

        setModalUpdate(true);
    };
    const handleSearch = () => {
        setSearching(true);
    };

    useEffect(() => {
        loadData()
    }, []);
    const loadData = async (activePage = null, itemPerPage = null, searchString = null) => {
        try {
            setLoading(true);

            const params = {
                activePage: activePage ? activePage : lastParams.activePage,
                itemPerPage: itemPerPage ? itemPerPage : lastParams.itemPerPage,
                searchString: searchString != null ? searchString : lastParams.searchString
            }

            const response = await api.get(
                `/adminarea/GetAllUser/${params.activePage}/${params.itemPerPage}?${buildURLQuery(
                    { search: params.searchString }
                )}`
            );
            setLastParams(params)
            const dataUsers = response.data;
            setTotalItens(dataUsers.indexSize);
            setPages(
                dataUsers.entities.map((x) => ({
                    ...x
                }))
            );
        } catch (error) {
            if (!!error.response && error.response.status !== 401) {
                toast.error("Erro ao carregar a página");
            }
        } finally {
            setLoading(false);
        }
    };

    async function EditUser(user) {

        setModalUserPWD({
            isOpen: true,
            user,
        })
        await GetAvailableRoles(user.email)
    }

    async function ChangePassword(data) {
        setModalLoading(true)
        try {
            const query = {
                email: modalUserPWD.user.email,
                password: data.newPassword
            }
            const response = await api.get(`/authentication/ResetPassword/${query.email}/${query.password}`)
            console.log(response);

            if (response.status) {
                toast.success(`${response.data.result.message}`);

            }
        } catch (error) {
            toast.error(`${error.message}`);

        }
        setModalUserPWD({ isOpen: false })
        setModalLoading(false)

    }

    async function CreateUser(_data) {
        if (!tenantChecked) {
            toast.warn("Selecione um local de acesso!")
            return;
        }
        const form = {
            userName: _data.name,
            email: _data.email,
            passwordHash: _data.password,
        }

        const response = await api.post(`/authentication/CreateUser/${tenantChecked}`, form);
        const { data } = response;

        if (data.status) {
            toast.success(response.data.message);
        } else {
            toast.error(response.data.message);
        }
    }

    async function GetAllTenants() {
        setModalCreateUser(true)
        const response = await api.get(`/authentication/GetALLTenants`);

        setAllTenants({ loading: false, data: response.data })
    }

    async function GetAvailableRoles(userEmail) {
        const { data } = await api.get(`/adminarea/GetRoles/${userEmail}`)
        const { data: userRolesData } = await api.get(`/adminarea/GetUserRole/${userEmail}`)
        setAvailableRoles({ loading: false, data: data })
        setUserRoles({ loading: false, data: userRolesData })
    }

    async function AddUserRole({ userEmail, role }) {
        setAvailableRoles({ loading: true, data: [] })
        setUserRoles({ loading: true, data: [] })
        const form = {
            userEmail,
            role
        }
        const response = await api.post(`/adminarea/AddUserRole`, form)

        if (response.data === "Role adicionada com sucesso") {
            toast.success("Acesso adicionado!")
        } else {
            toast.error(response.data)
        }
        GetAvailableRoles(userEmail)
    }

    async function RemoveUserRole({ userEmail, role }) {
        setAvailableRoles({ loading: true, data: [] })
        setUserRoles({ loading: true, data: [] })
        const form = {
            userEmail,
            role
        }
        const response = await api.post(`/adminarea/RemoveUserRole`, form)

        if (response.data === "Role removida com sucesso") {
            toast.info("Acesso removido!")
        } else {
            toast.error(response.data)
        }
        GetAvailableRoles(userEmail)
    }


    return (
        <>

            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
                <h1 className="h2">Gerenciamento de Usuários</h1>
            </div>


            <Row className="d-flex justify-content-center">
                <Col xs={12} sm={12} className="d-flex justify-content-end">
                    <Form
                        initialData={{ itensPerPage: lastParams.itemPerPage }}
                        className="mr-2"
                    >
                        <InputGroup className="">
                            <Select
                                name="itensPerPage"
                                placeholder="Items por página"
                                className="form-control"
                                options={[
                                    {
                                        value: 10,
                                        id: 10,
                                        title: 10,
                                    },
                                    {
                                        value: 25,
                                        id: 25,
                                        title: 25,
                                    },
                                    {
                                        value: 50,
                                        id: 50,
                                        title: 50,
                                    },
                                    {
                                        value: 100,
                                        id: 100,
                                        title: 100,
                                    },
                                ]}
                                onChange={({ target }) => loadData(null, target.value, null)}
                            />
                        </InputGroup>
                    </Form>

                    {!!searching ? (
                        <Form >
                            <InputGroup className="">
                                <FormControl
                                    type="text"
                                    placeholder="Procurar aluno"
                                    className="form-control inputSearch"
                                    onChange={handleFind}
                                    ref={searchRef}
                                />
                                <InputGroup.Append>
                                    <Button variant="outline-danger" onClick={handleClear}>
                                        <MD.MdClear />
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form>
                    ) : (
                        <Button
                            variant="outline-info"
                            onClick={handleSearch}
                            title="Clique para procurar"
                        >
                            <IoMdSearch />
                        </Button>
                    )}
                    <Button
                        className="ml-2"
                        variant="outline-info"
                        onClick={() => GetAllTenants()}
                        title="Clique para procurar"
                    >
                        <MD.MdAdd />
                    </Button>
                </Col>
            </Row>

            {/* List */}
            <Row className="d-flex justify-content-center">
                <Col xs={11} sm={12}>
                    <div className="table-responsive pt-3">
                        <Table responsive striped bordered hover variant="dark">
                            <thead>
                                <tr>
                                    <th>RA</th>
                                    <th>Email</th>
                                    <th>Campus</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan={8}>
                                            <div className="contentcenter">
                                                <Spinner animation="border" /> Carregando
                                            </div>
                                        </td>
                                    </tr>
                                ) : pages.length === 0 ? (
                                    <tr>
                                        <td colSpan={8} className="text-center">
                                            Nenhum registro
                                        </td>
                                    </tr>
                                ) : (
                                    pages.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{item.userName}</td>
                                            <td>{item.email}</td>
                                            <td>{item.dataKey}</td>

                                            <td className="tdControl">
                                                <Dropdown drop="left">
                                                    <Dropdown.Toggle
                                                        variant="secondary"
                                                        id="dropdown-basic"
                                                    >
                                                        <IoMdMenu size={22} />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item
                                                            onClick={() => EditUser(item)}
                                                        >
                                                            <RI.RiEdit2Line size={"18"} color="#17a2b8" /> Editar
                                                        </Dropdown.Item>
                                                        <Dropdown.Item

                                                        >

                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </Table>
                        <Container hidden={loading || pages.length === 0 ? true : false}>
                            <Row
                                className={`justify-content-center ${totalItens <= lastParams.itemPerPage ? "d-none" : "d-flex"
                                    }`}
                            >
                                <Col xs={10} sm={5} className="d-flex justify-content-center">
                                    <PaginationComponent
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        innerClass="pagination mb-0"
                                        activePage={lastParams.activePage}
                                        itemsCountPerPage={lastParams.itemPerPage}
                                        totalItemsCount={totalItens}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => loadData(e)}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
            {!!modalUserPWD?.user

                &&

                <Modal
                    className="mt-5"
                    size="md"
                    show={modalUserPWD.isOpen}
                    onHide={() => setModalUserPWD({ isOpen: false })}
                    aria-labelledby="example-modal-sizes-title-lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Redefinir senha
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row className="justify-content-center">
                            <Col >
                                <Form onSubmit={ChangePassword} >
                                    <Jumbotron className="py-4">
                                        <h5>
                                            <b>RA: </b>
                                            {modalUserPWD.user.userName}
                                        </h5>
                                        <h5>
                                            <b>Email: </b>
                                            {modalUserPWD.user.email}
                                        </h5>
                                    </Jumbotron>
                                    <Jumbotron className="py-4">
                                        <Row>
                                            <h4>Tipos de acessos disponíveis:</h4>
                                        </Row>
                                        {availableRoles.loading && userRoles.loading ?
                                            <Spinner animation="border"></Spinner>
                                            :
                                            <>
                                                {availableRoles.data.map((x) => (
                                                    <Row className="align-self-center" >

                                                        <label className="switchBox" htmlFor="available">
                                                            <Switch
                                                                onChange={() => {
                                                                    !!userRoles.data.find(role => role === x) ?
                                                                        RemoveUserRole({ userEmail: modalUserPWD.user.email, role: x })
                                                                        :
                                                                        AddUserRole({ userEmail: modalUserPWD.user.email, role: x })
                                                                }}
                                                                size={"10"}
                                                                id="available"
                                                                checked={!!userRoles.data.find(role => role === x)}
                                                            />
                                                            <span>{x}</span>
                                                        </label>
                                                    </Row>

                                                ))}
                                            </>
                                        }
                                    </Jumbotron>





                                    <Input
                                        required
                                        name="newPassword"
                                        type="password"
                                        className="form-control mb-2"
                                        placeholder="Nova senha"
                                    />

                                    <Row>
                                        <Col>
                                            <Button
                                                onClick={() => setModalUserPWD({ isOpen: false })}
                                                className="my-4"
                                                variant="danger"
                                                block
                                            >
                                                Cancelar
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button
                                                type="submit"
                                                className="my-4"
                                                disabled={modalLoading}
                                                block
                                            >
                                                {!modalLoading ? "Atualizar Senha" : "Atualizando..."}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            }

            {<Modal size="md" show={modalCreateUser} onHide={() => { handleClose(); setTenantChecked() }} >
                <Modal.Header closeButton>
                    <Modal.Title ><MD.MdPersonAdd size={30} /> <span className="align-middle">Adicionar Usuário</span></Modal.Title>

                </Modal.Header>
                <Form onSubmit={CreateUser}>
                    <Modal.Body>
                        <Row className="mb-3" >
                            <Col>
                                <h6>Usuário</h6>
                                <Input
                                    name="name"
                                    required
                                    className="form-control"
                                    placeholder="nome.sobrenome"
                                    autoFocus
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3" >
                            <Col>
                                <h6>Email</h6>
                                <Input
                                    type="email"
                                    name="email"
                                    required
                                    className="form-control"
                                    placeholder="name@example.com"
                                    autoFocus
                                />
                            </Col>
                        </Row>
                        <Row >
                            <Col>
                                <h6>Senha</h6>
                                <Input type="password"
                                    className="form-control"
                                    name="password"
                                    required placeholder="Senha" />
                            </Col>
                        </Row>
                        <Row >
                            <Col>

                                <Jumbotron className="py-4 my-2">
                                    <Row>
                                        <h4>Local de acesso:</h4>
                                    </Row>
                                    {AllTenants.loading ?
                                        <Spinner animation="border"></Spinner>
                                        :
                                        <>
                                            <ButtonGroup
                                                toggle
                                                name="radio"
                                                style={{
                                                    gap: ".5rem",
                                                    flexWrap: "wrap",
                                                }}
                                            >
                                                {AllTenants.data.map((x) => (

                                                    <ToggleButton
                                                        key={x.name}
                                                        type="radio"
                                                        checked={tenantChecked == x.dataKey}
                                                        onChange={() => setTenantChecked(x.dataKey)}
                                                        value={x.dataKey}
                                                        variant="secondary"
                                                        style={{
                                                            background: tenantChecked == x.dataKey ? "#007bff" : "gray",
                                                            borderRadius: ".4rem",
                                                            padding: "0.5rem",
                                                            maxWidth: "9rem",
                                                            whiteSpace: "nowrap",
                                                            width: "min-content",
                                                        }}
                                                    >
                                                        {x.name}
                                                    </ToggleButton>


                                                ))}
                                            </ButtonGroup>

                                        </>
                                    }
                                </Jumbotron>
                            </Col>
                        </Row>
                        <Row >
                            <Col>
                                <Alert variant={"info"}>
                                    Por padrão, usuários criados já estarão com acesso de administrador!
                                </Alert>
                            </Col>
                        </Row>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { handleClose(); setTenantChecked() }}>
                            Fechar
                        </Button>
                        <Button variant="primary" type="submit">
                            Criar Usuário
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal >}

        </>
    )
}