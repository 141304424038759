import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { signOut } from "~/store/modules/auth/actions";
import { updateTenantProfile } from "~/store/modules/header/actions";

import { Form, Select } from "@rocketseat/unform";

import { Navbar, Nav, Card, Dropdown, Button, Modal } from "react-bootstrap";
import logo from "~/assets/a_unasp.png";
import { Wrapper } from "./styles";

import { IoMdMenu } from "react-icons/io";
import { GiOrganigram } from "react-icons/gi";
import { FiLogOut } from "react-icons/fi";

import JsonMenu from "~/pages/__layouts/admin/Components/menu";

export default function Header() {
  const dispatch = useDispatch();
  let location = useLocation();

  const profile = useSelector((state) => state.user.profile);
  const template = useSelector((state) => state.user.template);

  const [selectOrganization, setSelectOrganization] = useState(false);

  const tenantProfile = useSelector((state) => state.header.tenantProfile);

  const allTenants = [
    {
      id: "EC",
      value: "EC",
      title: "Engenheiro Coelho",
    },
    {
      id: "HT",
      value: "HT",
      title: "Hortolândia",
    },
    {
      id: "SP",
      value: "SP",
      title: "São Paulo",
    },
    {
      id: "EAD",
      value: "EAD",
      title: "EAD",
    },
    {
      id: "ROOT",
      value: "ROOT",
      title: "Acesso Master",
    },
  ];

  useEffect(() => {
    if (tenantProfile == "") {
      setSelectOrganization(true);
    }
  }, []);

  const renderMenu = () => {
    switch (template) {
      case "admin":
        return (
          <>
            <Dropdown.Item onClick={() => setSelectOrganization(true)}>
              <GiOrganigram /> Selecionar organização
            </Dropdown.Item>
            {/* <Dropdown.Item onClick={() => setModalUser(true)}>
              <FaUserEdit /> Atualizar dados de cadastro
            </Dropdown.Item> */}
            <Dropdown.Item onClick={() => dispatch(signOut())}>
              <FiLogOut /> Logout
            </Dropdown.Item>
          </>
        );

      default:
        break;
    }
  };

  const renderOptions = () => {
    const { tenants } = profile;
    return allTenants.filter((el) => tenants.indexOf(el.id) > -1);
  };

  const handleChangeOrganization = async (data) => {
    const { organization } = data;
    dispatch(updateTenantProfile(organization));
    setSelectOrganization(false);
  };

  return (
    <Wrapper>
      {profile != null && !!profile.tenants && (
        <>
          <Navbar bg="dark" variant="dark" className="py-2">
            <Dropdown className="d-block d-sm-none">
              <Dropdown.Toggle
                variant="success"
                drop={`left`}
                id={`dropdown-button-icon`}
              >
                <IoMdMenu color="#fff" size={22} />
              </Dropdown.Toggle>
              <Dropdown.Menu id="listItems">
                {JsonMenu.map((x, i) => (
                  <Link
                    key={i}
                    to={x.url}
                    className={`nav-link ${
                      location.pathname
                        .toLowerCase()
                        .includes(x.url.toLowerCase())
                        ? "active"
                        : ""
                    }`}
                  >
                    {x.icon} {x.label}
                  </Link>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Navbar.Brand id="brand" href="/">
              <img src={logo} />
            </Navbar.Brand>
            <Nav className="mr-auto"></Nav>
            <Nav className="">
              {profile && (
                <>
                  <span
                    className="mr-2 align-self-center text-light"
                    style={{ cursor: "pointer" }}
                    onClick={() => setSelectOrganization(true)}
                  >
                    {!!tenantProfile &&
                      allTenants.find((x) => x.id == tenantProfile).title}
                  </span>

                  <Dropdown id="dropDownMenu">
                    <Dropdown.Toggle
                      variant="link"
                      className="bg-transparent text-white"
                      id="dropdown-basic"
                    ></Dropdown.Toggle>
                    <Dropdown.Menu alignRight>{renderMenu()}</Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </Nav>
          </Navbar>

          {/* Select Organization */}
          <Modal
            size="md"
            show={selectOrganization}
            aria-labelledby="example-modal-sizes-title-lg"
            backdrop="static"
            onHide={() => setSelectOrganization(!!tenantProfile ? false : true)}
          >
            <Modal.Body>
              <Card bg="light" text="dart">
                <Card.Header>
                  <h4>Selecione a organização</h4>
                </Card.Header>
                <Card.Body>
                  <Form
                    initialData={{ organization: tenantProfile }}
                    onSubmit={handleChangeOrganization}
                  >
                    <Select
                      name="organization"
                      required
                      placeholder="Selecione..."
                      className="form-control mb-4"
                      options={renderOptions()}
                    />

                    <Button variant="info" type="submit" block>
                      Carregar organização
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Modal.Body>
          </Modal>
        </>
      )}
    </Wrapper>
  );
}
