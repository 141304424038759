import produce from "immer";

const INITIAL_STATE = {
  profile: null,
  template: "",
};
export default function user(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@auth/SIGN_IN_SUCCESS":
        draft.profile = action.payload.user;
        draft.template = action.payload.type;
        break;
      case "@user/UPDATE_PROFILE_SUCCESS":
        draft.profile = action.payload.profile;
        break;
      case "@user/UPDATE_ACCEPT_REGULATION_SUCCESS":
        draft.profile.regulationAccepted = action.payload.status;
        break;
      case "@user/UPDATE_IMAGE_PROFILE":
        draft.profile.avatar = action.payload.avatar;
        break;
      case "@user/UPDATE_EMAIL_CONFIRMATION":
        const {
          newEmail,
          newEmailConfirmed,
          codeExpiration,
        } = action.payload.Confirmation;
        const usr = {
          ...draft.profile,
          updateEmail: { newEmail, newEmailConfirmed, codeExpiration },
        };

        draft.profile = usr;
        break;
      case "@auth/SIGN_OUT": {
        draft.profile = null;
        draft.template = "";
        break;
      }
      default:
    }
  });
}
