import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { formatPrice } from "~/util/format";
import explodeDate from "~/util/explodeDate";
import parseDataBradesco from "~/util/parseDataBradesco";

import { format, addMonths, addDays } from "date-fns";

import { Wrapper } from "./styles";
import {
  Container,
  Row,
  Col,
  Alert,
  InputGroup,
  Button,
  Spinner,
  Modal,
  Card,
  Badge,
} from "react-bootstrap";
import InputMask from "react-input-mask";

import Switch from "react-switch";

import { Form, Input, Select } from "@rocketseat/unform";

import BoletoComponent from "~/components/BankSlip/BoletoComponent";

import api from "~/services/api";

export default function BankSlip({ idclient, data, routePay, setInvoice }) {
  const { profile, template } = useSelector((state) => state.user);

  const [donorName, setDonorName] = useState("");
  const [donorMail, setDonorMail] = useState("");

  const [nome, setNome] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [numero, setNumero] = useState("");
  const [complemento, setComplemento] = useState("");
  const [cep, setCep] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [uf, setUf] = useState("");
  const [cpf, setCpf] = useState("");
  const [price, setPrice] = useState("");
  const [customPrice, setCustomPrice] = useState("");

  const [slipData, setSlipData] = useState(null);
  const [identificacao, setIdentificacao] = useState(false);

  const [loading, setLoading] = useState(false);
  const [authPeople, setAuthPeople] = useState(false);

  const [modalTermUse, setModalTermUse] = useState(false);
  const [acceptTerm, setAcceptTerm] = useState(false);
  const [acceptted, setAcceptted] = useState(false);
  const [termoDeUso, setTermoDeUso] = useState("");

  useEffect(() => {
    if (profile !== null && template === "donor") {
      setDonorName(profile.name);
      setDonorMail(profile.email);
      setAuthPeople(true);

      if (profile.regulationAccepted) {
        setAcceptTerm(true);
        setAcceptted(true);
      }
    }

    setTermoDeUso(data.giverRegulation);
  }, []);

  useEffect(() => {
    setPrice(customPrice != "outro" ? customPrice : "");
  }, [customPrice]);

  async function handlePay(e) {
    //e.preventDefault();
    if (!acceptTerm) {
      toast.error("Você precisa aceitar os termos de uso");
      return;
    }

    if (price.trim() == "" || price.trim() == "0") {
      toast.error("O valor deve ser maior que zero");
      return;
    }

    const cepSplit = cep.trim().split("-");
    const jsonData = {
      DonationPageId: idclient,
      GiverName: donorName,
      GiverEmail: donorMail,
      Value: Number(price),
      IsRecurrence: false,
      RecurrenceTimes: 0,
      AnonymousDonator: identificacao,
      PaymentMethodCode: 20,
      PaymentParameters: JSON.stringify({
        dtEmissaoTitulo: format(Date.now(), "dd.MM.yyyy"),
        dtVencimentoTitulo: format(addDays(Date.now(), 4), "dd.MM.yyyy"),
        tpVencimento: "0",
        vlNominalTitulo: parseInt(Number(price) * 100),
        nomePagador: nome.trim().replace(/\s{2,}/g, " "),
        logradouroPagador: logradouro.trim().replace(/\s{2,}/g, " "),
        nuLogradouroPagador: numero.trim(),
        complementoLogradouroPagador: complemento
          .trim()
          .replace(/\s{2,}/g, " "),
        cepPagador: cepSplit[0],
        complementoCepPagador: cepSplit[1],
        bairroPagador: bairro.trim().replace(/\s{2,}/g, " "),
        municipioPagador: cidade.trim().replace(/\s{2,}/g, " "),
        ufPagador: uf.trim(),
        cdIndCpfcnpjPagador: "1",
        nuCpfcnpjPagador: cpf.trim().replace(/[^0-9]/g, ""),
        cdEspecieTitulo: "04",
        tpProtestoAutomaticoNegativacao: "0",
        prazoProtestoAutomaticoNegativacao: "0",
        controleParticipante: "",
        cdPagamentoParcial: "",
        qtdePagamentoParcial: "0",
        percentualJuros: "0",
        vlJuros: "0",
        qtdeDiasJuros: "0",
        percentualMulta: "0",
        vlMulta: "0",
        qtdeDiasMulta: "0",
        percentualDesconto1: "0",
        vlDesconto1: "0",
        dataLimiteDesconto1: "",
        percentualDesconto2: "0",
        vlDesconto2: "0",
        dataLimiteDesconto2: "",
        percentualDesconto3: "0",
        vlDesconto3: "0",
        dataLimiteDesconto3: "",
        prazoBonificacao: "0",
        percentualBonificacao: "0",
        vlBonificacao: "0",
        dtLimiteBonificacao: "",
        vlAbatimento: "0",
        vlIOF: "0",
        endEletronicoPagador: "",
        nomeSacadorAvalista: "",
        logradouroSacadorAvalista: "",
        nuLogradouroSacadorAvalista: "0",
        complementoLogradouroSacadorAvalista: "",
        cepSacadorAvalista: "0",
        complementoCepSacadorAvalista: "0",
        bairroSacadorAvalista: "",
        municipioSacadorAvalista: "",
        ufSacadorAvalista: "",
        cdIndCpfcnpjSacadorAvalista: "0",
        nuCpfcnpjSacadorAvalista: "0",
        endEletronicoSacadorAvalista: "",
      }),
    };

    try {
      setLoading(true);
      const response = await api.post(`${routePay}`, jsonData);
      const dataResponse = response.data;

      if (response.status == 200) {
        if (dataResponse.status) {
          setSlipData(parseDataBradesco(dataResponse.entity.paymentData));
          toast.success("Boleto criado com sucesso");
        } else {
          error(dataResponse.message);
        }
      } else {
        error(dataResponse.message);
      }
    } catch (error) {
      toast.error("Ocorreu um erro, tente novamente");
    } finally {
      setLoading(false);
    }
  }

  function emitCallBack(d) {
    if (d.status) {
      setInvoice(d.invoice);
    }
  }

  function error(message) {
    toast.error(`Ocorreu um erro com a financeira. ${!!message && message}`);
  }

  return (
    <Wrapper>
      <Container>
        {!!idclient && !!routePay ? (
          <div id="PaymentForm">
            <Row className="justify-content-center">
              {slipData != null ? (
                <Col xs={12} sm={12}>
                  <BoletoComponent data={slipData} />
                </Col>
              ) : (
                <Col xs={12} sm={10} className="mr-3">
                  <Form
                    id="formDonationBankSlip"
                    onSubmit={handlePay}
                    initialData={{
                      donorName: donorName,
                      donorMail: donorMail,
                    }}
                  >
                    <Row className=" justify-content-center">
                      <Col xs={12} md={8} lg={6}>
                        <InputGroup className="mb-3">
                          <Input
                            type="text"
                            name="donorName"
                            className="form-control"
                            placeholder="Nome do doador"
                            disabled={authPeople}
                            required={!authPeople}
                            onChange={(e) => setDonorName(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={8} lg={6}>
                        <InputGroup className="mb-3">
                          <Input
                            type="email"
                            name="donorMail"
                            className="form-control"
                            placeholder="email@email.com"
                            disabled={authPeople}
                            required={!authPeople}
                            onChange={(e) => setDonorMail(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={8} lg={12} className="pb-2">
                        <Badge variant="secondary">
                          Obs: obrigatório para envio do comprovante da doação
                        </Badge>
                        <hr />
                        <h5>Dados do boleto</h5>
                      </Col>
                      <Col xs={12} md={8} lg={6}>
                        <InputGroup className="mb-3">
                          <Input
                            className="form-control"
                            type="text"
                            name="nome"
                            placeholder="Nome"
                            required
                            onChange={(e) => setNome(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={8} lg={6}>
                        <InputGroup className="mb-3">
                          <InputMask
                            className="form-control"
                            mask="999.999.999-99"
                            maskChar=" "
                            type="tel"
                            name="cpf"
                            placeholder="CPF"
                            required
                            onChange={(e) => setCpf(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={8} lg={12}>
                        <InputGroup className="mb-3">
                          <Input
                            className="form-control"
                            type="text"
                            name="logradouro"
                            placeholder="Logradouro"
                            required
                            onChange={(e) => setLogradouro(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={8} lg={3}>
                        <InputGroup className="mb-3">
                          <InputMask
                            className="form-control"
                            mask="99999999"
                            maskChar=" "
                            type="tel"
                            name="numero"
                            placeholder="Número"
                            required
                            onChange={(e) => setNumero(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={8} lg={4}>
                        <InputGroup className="mb-3">
                          <Input
                            className="form-control"
                            name="complemento"
                            placeholder="Complemento"
                            onChange={(e) => setComplemento(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={8} lg={5}>
                        <InputGroup className="mb-3">
                          <InputMask
                            className="form-control"
                            mask="99999-999"
                            maskChar=" "
                            type="tel"
                            name="cep"
                            placeholder="CEP"
                            required
                            onChange={(e) => setCep(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={8} lg={4}>
                        <InputGroup className="mb-3">
                          <Input
                            className="form-control"
                            name="bairro"
                            placeholder="Bairro"
                            required
                            onChange={(e) => setBairro(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={8} lg={4}>
                        <InputGroup className="mb-3">
                          <Input
                            className="form-control"
                            name="cidade"
                            placeholder="Cidade"
                            required
                            onChange={(e) => setCidade(e.target.value)}
                          />
                        </InputGroup>
                      </Col>
                      <Col xs={12} md={8} lg={4}>
                        <InputGroup className="mb-3">
                          <Select
                            name="uf"
                            required
                            placeholder="Estado"
                            className="form-control"
                            options={[
                              { id: "AC", title: "Acre" },
                              { id: "AL", title: "Alagoas" },
                              { id: "AP", title: "Amapá" },
                              { id: "AM", title: "Amazonas" },
                              { id: "BA", title: "Bahia" },
                              { id: "CE", title: "Ceará" },
                              { id: "DF", title: "Distrito Federal" },
                              { id: "ES", title: "Espírito Santo" },
                              { id: "GO", title: "Goiás" },
                              { id: "MA", title: "Maranhão" },
                              { id: "MT", title: "Mato Grosso" },
                              { id: "MS", title: "Mato Grosso do Sul" },
                              { id: "MG", title: "Minas Gerais" },
                              { id: "PA", title: "Pará" },
                              { id: "PB", title: "Paraíba" },
                              { id: "PR", title: "Paraná" },
                              { id: "PE", title: "Pernambuco" },
                              { id: "PI", title: "Piauí" },
                              { id: "RJ", title: "Rio de Janeiro" },
                              { id: "RN", title: "Rio Grande do Norte" },
                              { id: "RS", title: "Rio Grande do Sul" },
                              { id: "RO", title: "Rondônia" },
                              { id: "RR", title: "Roraima" },
                              { id: "SC", title: "Santa Catarina" },
                              { id: "SP", title: "São Paulo" },
                              { id: "SE", title: "Sergipe" },
                              { id: "TO", title: "Tocantins" },
                            ]}
                            onChange={(e) => setUf(e.target.value)}
                          />
                        </InputGroup>
                      </Col>

                      <Col xs={12} md={8} lg={12} className="pb-2">
                        <hr />
                      </Col>
                      <Col xs={12} md={8} lg={6}>
                        <label className="switchBox">
                          <span>Doação Anônima</span>
                          <Switch
                            onChange={setIdentificacao}
                            checked={identificacao}
                          />
                        </label>
                      </Col>

                      <Col xs={12} md={8} lg={6}>
                        <InputGroup className="mb-3">
                          <Select
                            name="valor"
                            required
                            placeholder="Valor da doação:"
                            className="form-control"
                            options={[
                              { id: "100", title: "R$ 100,00" },
                              { id: "50", title: "R$ 50,00" },
                              { id: "20", title: "R$ 20,00" },
                              { id: "10", title: "R$ 10,00" },
                              { id: "outro", title: "Outro valor" },
                            ]}
                            onChange={(e) => setCustomPrice(e.target.value)}
                          />
                        </InputGroup>
                      </Col>

                      {customPrice == "outro" && (
                        <Col xs={10} md={7} lg={5} className="offset-lg-6">
                          <InputGroup className="mb-3 ml-4">
                            <InputMask
                              value={price}
                              type="number"
                              name="price"
                              className="form-control"
                              placeholder="Outro Valor:"
                              required
                              onChange={(e) => setPrice(e.target.value)}
                            />
                          </InputGroup>
                        </Col>
                      )}

                      <Col xs={12} sm={8} className="mb-1 mt-2">
                        <h5>Total: {formatPrice(price)}</h5>
                      </Col>
                      {!acceptted && (
                        <Col
                          xs={12}
                          sm={8}
                          lg={12}
                          className="mb-1 mt-2 text-muted text-center"
                        >
                          <label
                            className="switchBox"
                            style={{ justifyContent: "center" }}
                          >
                            <Switch
                              onChange={setAcceptTerm}
                              checked={acceptTerm}
                            />
                            <span>
                              Li e aceito os{" "}
                              <Button
                                variant="link"
                                className="px-0"
                                onClick={() => setModalTermUse(true)}
                              >
                                termos de uso
                              </Button>
                            </span>
                          </label>
                        </Col>
                      )}
                      <Col xs={12} md={6}>
                        <Button type="submit" block disabled={loading}>
                          {loading ? (
                            <Spinner animation="border" />
                          ) : (
                            `Gerar Boleto`
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              )}
            </Row>
          </div>
        ) : (
          <Alert variant="danger">
            Estão faltando alguns parâmetros para renderizar este componente
          </Alert>
        )}
      </Container>

      {/* Modal Termo de Uso */}
      <Modal
        className="mt-5"
        size="lg"
        show={modalTermUse}
        onHide={() => setModalTermUse(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Termo de uso
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card bg="white" text="dark">
            <Card.Body>
              <Card.Text
                className="h6 text-justify"
                dangerouslySetInnerHTML={{
                  __html: termoDeUso,
                }}
              ></Card.Text>
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    </Wrapper>
  );
}
