import axios from "axios";
import { toast } from "react-toastify";
import history from "~/services/history";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }
    toast.error("Sua sessão expirou. Faça o login novamente");
    history.push("/logout");
  }
);

export default api;
