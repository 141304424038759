import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Form, Input } from "@rocketseat/unform";
import { FaUserPlus } from "react-icons/fa";

import * as Yup from "yup";
import { signUpRequest } from "~/store/modules/auth/actions";
import { Button } from "react-bootstrap";
import logo from "~/assets/LogoBranco.png";

const schema = Yup.object().shape({
  name: Yup.string().required("O nome é obrigatório"),
  email: Yup.string()
    .email("Insira um e-amil válido")
    .required("O e-mail é obrigatório"),
  password: Yup.string()
    .min(6, "No minimo 6 caracteres")
    .required("A senha é obrigatória"),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Senhas diferentes"
  ),
});

export default function SignUp() {
  const dispatch = useDispatch();
  function handleSubmit({ name, email, password, confirmPassword }) {
    dispatch(signUpRequest(name, email, password, confirmPassword));
  }

  return (
    <>
      <div
        className="p-4 position-relative"
        style={{ zIndex: 2, backgroundColor: "#003366", borderRadius: "12px" }}
      >
        <img src={logo} height="60px" className="my-3" />
        <h3 className="mt-3 text-light">Registrar a sua conta</h3>
        <h6 className="text-light">Crie sua conta</h6>
        <Form schema={schema} onSubmit={handleSubmit}>
          <>
            <Input
              name="name"
              type="text"
              required
              placeholder="Nome Completo"
              style={{ backgroundColor: "#f3f2f3" }}
            />
            <Input
              name="email"
              type="email"
              required
              placeholder="E-mail"
              style={{ backgroundColor: "#f3f2f3" }}
            />
            <Input
              style={{ backgroundColor: "#f3f2f3" }}
              name="password"
              type="password"
              required
              placeholder="Senha"
            />
            <Input
              style={{ backgroundColor: "#f3f2f3" }}
              name="confirmPassword"
              type="password"
              required
              placeholder="Confirme sua senha"
            />
          </>

          <Button type="submit">Criar</Button>
          <Link to="/login" style={{ color: "#f3f2f3" }}>
            Já tenho login
          </Link>
        </Form>
      </div>
    </>
  );
}
