import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "~/services/api";
import urlsystem from "~/config/URLSystem";
import buildURLQuery from "~/util/buildURLQuery";

import enumState from "~/util/enumState";
import Switch from "react-switch";
import youtube_parser from "~/util/youtube";

import { format } from "date-fns";
import pt from "date-fns/locale/pt";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { Form, Input, Select } from "@rocketseat/unform";
import {
  Table,
  Container,
  Row,
  Col,
  Dropdown,
  Spinner,
  Button,
  Modal,
  Card,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { toast } from "react-toastify";

import PaginationComponent from "react-js-pagination";
// import { Container } from './styles';
import { IoMdMenu, IoMdSearch } from "react-icons/io";
import { MdUpdate, MdClear, MdVideoLibrary } from "react-icons/md";
import { FaCheck, FaTimes } from "react-icons/fa";

export default function ModerarAlteracoes() {
  const tenantProfile = useSelector((state) => state.header.tenantProfile);

  const formRef = useRef(null);
  const searchRef = useRef(null);
  const [searching, setSearching] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [timeout, setTimeOut] = useState(0);

  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  const [activePage, setActivePage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(0);

  const [statusAprove, setStatusAprove] = useState(0);

  const [updatePending, setUpdatePending] = useState({});
  const [modalUpdate, setModalUpdate] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);

  const [modalVideo, setModalVideo] = useState(false);
  const [videoEmbed, setVideoEmbed] = useState("");

  const [isActiveEdit, setIsActiveEdit] = useState(null);

  // change new tenant
  useEffect(() => {
    setActivePage(1);
  }, [tenantProfile, searchString]);

  /* Load data pages */
  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);

        const response = await api.get(
          `/AdminArea/GetDonationPageUpdateRequests/${tenantProfile}/${activePage}/${itemPerPage}?${buildURLQuery(
            { search: searchString }
          )}`
        );

        const dataDonations = response.data;
        setTotalItens(dataDonations.indexSize);
        setPages(
          dataDonations.entities.map((x) => ({
            ...x,
            urlFormatted: `${urlsystem}/student/${x.url}`,
            dateFormatted: x.requestDate
              ? format(new Date(x.requestDate), "dd/MMM/yyyy HH:mm", {
                  locale: pt,
                })
              : null,
          }))
        );
      } catch (error) {
        if (!!error.response && error.response.status !== 401) {
          toast.error("Erro ao carregar a página");
        }
      } finally {
        setLoading(false);
      }
    };
    loadData();
  }, [activePage, setItemPerPage, tenantProfile, searchString]);

  useEffect(() => {
    if (searching) {
      searchRef.current.focus();
    }
  }, [searching]);

  const handleViewRequest = (data) => {
    setUpdatePending({});

    setIsActiveEdit(data.pageActiveStatus);
    setUpdatePending({
      ...data,
      embed_video: !!data.videoUrl ? youtube_parser(data.videoUrl) : false,
    });
    setModalUpdate(true);
  };

  const handleModalVideo = (embed) => {
    setVideoEmbed(embed);
    setModalVideo(true);
  };

  const handleUpdatePage = async (data) => {
    setModalLoading(true);

    const {
      donationPageId,
      moderationMessage,
      pageDescription,
      text,
      updateVerificationCode,
      videoUrl,
    } = data;

    const videoIsValid = !!videoUrl.trim() ? !!youtube_parser(videoUrl) : true;

    if (videoIsValid) {
      const objData = {
        pageId: donationPageId,
        text,
        pageDescription,
        videoUrl: videoUrl,
        status: Number(statusAprove),
        moderationMessage,
        updateVerificationCode,
      };

      try {
        const response = await api.put(
          `/adminarea/moderatedonationpage/${tenantProfile}`,
          objData
        );
        const data = response.data;
        const { message, id, entity } = data;
        if (response.status === 200 && data.status) {
          setPages(pages.filter((x) => x.donationPageId !== id));
          toast.success("Salvo com sucesso");
          setUpdatePending({});
          setModalUpdate(false);
        } else {
          toast.error(`Erro ao atualizar.. ${message}`);
        }
      } catch (error) {
        if (!!error.response && error.response.status !== 401) {
          toast.error("Erro ao atualizar.. tente mais tarde");
        }
      } finally {
        setModalLoading(false);
      }
    } else {
      setModalLoading(false);
      toast.error("Link do YouTube inválido");
    }
  };

  const handleUpdateStatePage = async (status, handleId) => {
    confirmAlert({
      title: "Confirme",
      message: `Deseja realmente ${
        status ? "ativar" : "bloquear"
      } esta página?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            setModalLoading(true);
            try {
              const response = await api.put(
                `/AdminArea/ChangePageActiveStatus/${tenantProfile}/${handleId}`
              );
              const data = response.data;

              const { message, id, entity } = data;
              if (response.status === 200 && data.status) {
                setIsActiveEdit(status);

                setPages(
                  pages.map((x) =>
                    x.donationPageId === id
                      ? {
                          ...x,
                          urlFormatted: `${urlsystem}/student/${x.url}`,
                          pageActiveStatus: entity.active,
                        }
                      : {
                          ...x,
                        }
                  )
                );
                toast.success(`Status alterado`);
              } else {
                toast.error(`Erro na atualização. ${message}`);
              }
            } catch (error) {
              toast.error(`Erro na comunicação com o servidor.`);
            } finally {
              setModalLoading(false);
            }
          },
        },
        {
          label: "Não",
          onClick: () => {},
        },
      ],
    });
  };

  const handleFind = (e) => {
    const vl = e.target.value;

    if (timeout) clearTimeout(timeout);
    setTimeOut(
      setTimeout(() => {
        setSearchString(vl);
      }, 400)
    );
  };

  const handleClear = () => {
    setSearchString("");
    setSearching(false);
  };

  const handleSearch = () => {
    setSearching(true);
  };

  const searchFunction = (data) => {};

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Moderar Alterações</h1>
      </div>
      {/* Search */}
      <Row className="d-flex justify-content-center">
        <Col xs={11} sm={12} className="d-flex justify-content-end mb-2">
          {!!searching ? (
            <Form ref={formRef} onSubmit={searchFunction}>
              <InputGroup className="">
                <FormControl
                  type="text"
                  placeholder="Procurar aluno"
                  className="form-control inputSearch"
                  //value={searchString}
                  onChange={handleFind}
                  ref={searchRef}
                />
                <InputGroup.Append>
                  <Button variant="outline-danger" onClick={handleClear}>
                    <MdClear />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          ) : (
            <Button
              variant="outline-info"
              onClick={handleSearch}
              title="Clique para procurar"
            >
              <IoMdSearch />
            </Button>
          )}
        </Col>
      </Row>

      {/* List */}
      <Row className="d-flex justify-content-center">
        <Col xs={11} sm={12}>
          <div className="table-responsive pt-3">
            <Table responsive striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Código da notificação</th>
                  <th>Aluno</th>
                  <th>Status</th>
                  <th>Data</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={5}>
                      <div className="contentcenter">
                        <Spinner animation="border" /> Carregando
                      </div>
                    </td>
                  </tr>
                ) : pages.length === 0 ? (
                  <tr>
                    <td colSpan={5} className="text-center">
                      Nenhum registro
                    </td>
                  </tr>
                ) : (
                  pages.map((item, index) => (
                    <tr key={item.updateVerificationCode}>
                      <td>{item.updateVerificationCode}</td>
                      <td>{item.studentName}</td>
                      <td>
                        {enumState.find((x) => x.id === item.status).title}
                      </td>
                      <td>{item.dateFormatted}</td>
                      <td className="tdControl">
                        <Dropdown drop="left">
                          <Dropdown.Toggle
                            variant="secondary"
                            id="dropdown-basic"
                          >
                            <IoMdMenu size={22} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Header>{`${item.pageDescription}`}</Dropdown.Header>
                            <Dropdown.Item
                              onClick={() => handleViewRequest(item)}
                            >
                              <MdUpdate size={18} color="#17a2b8" /> Visualizar
                              alteração
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            <Container hidden={loading || pages.length === 0 ? true : false}>
              <Row
                className={`justify-content-center ${
                  totalItens <= itemPerPage ? "d-none" : "d-flex"
                }`}
              >
                <Col xs={10} sm={5} className="d-flex justify-content-center">
                  <PaginationComponent
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination mb-0"
                    activePage={activePage}
                    itemsCountPerPage={itemPerPage}
                    totalItemsCount={totalItens}
                    pageRangeDisplayed={5}
                    onChange={(e) => setActivePage(e)}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>

      {/* Modal Atualizar Página */}
      <Modal
        className="mt-5"
        size="lg"
        show={modalUpdate}
        onHide={() => setModalUpdate(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Moderador</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="justify-content-center">
            <Col>
              <Card bg="light" className="text-center mb-4 bshadow">
                <Card.Header>
                  <b>Avaliar página</b>
                </Card.Header>
                <Card.Body className="py-4">
                  <Row>
                    <Col xs={12} sm={6} className="text-left">
                      <b>Nome:</b>{" "}
                      {updatePending.studentName || "Não informado"}
                      <br />
                      {updatePending.url && (
                        <>
                          <b>Link</b> {updatePending.urlFormatted}
                        </>
                      )}
                    </Col>

                    <Col xs={12} sm={6}>
                      {isActiveEdit != null && (
                        <label className="switchBox mb-0">
                          <span>Ativar Página</span>
                          <Switch
                            onChange={(status) =>
                              handleUpdateStatePage(
                                status,
                                updatePending.donationPageId
                              )
                            }
                            checked={isActiveEdit}
                          />
                        </label>
                      )}
                    </Col>
                  </Row>
                  <hr />
                  <>
                    <Form
                      initialData={updatePending}
                      onSubmit={handleUpdatePage}
                    >
                      <Input name="donationPageId" type="hidden" />
                      <Row>
                        <Col xs={12} sm={6}>
                          <div className="form-group">
                            <label htmlFor="fieldVerify">
                              Codigo de notificação
                            </label>
                            <Input
                              name="updateVerificationCode"
                              id="fieldVerify"
                              disabled
                              placeholder=""
                              className="form-control"
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={6}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flex: 1,
                              alignItems: "center",
                              width: "100%",
                              justifyContent: "start",
                            }}
                          >
                            <div
                              className="form-group"
                              style={{ width: "inherit" }}
                            >
                              <label htmlFor="fieldYoutubeLink">YouTube</label>
                              <Input
                                name="videoUrl"
                                id="fieldYoutubeLink"
                                placeholder=""
                                className="form-control"
                              />
                            </div>
                            {!!updatePending.embed_video && (
                              <Button
                                className="ml-2 mt-2"
                                onClick={() =>
                                  handleModalVideo(updatePending.embed_video)
                                }
                              >
                                <MdVideoLibrary size={20} />
                              </Button>
                            )}
                          </div>
                        </Col>
                        <Col xs={12} sm={12}>
                          <div className="form-group">
                            <label htmlFor="fieldDescriptionPage">
                              Descrição da Página
                            </label>
                            <Input
                              name="pageDescription"
                              required
                              id="fieldDescriptionPage"
                              className="form-control"
                              maxLength="50"
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={12}>
                          <div className="form-group">
                            <label htmlFor="fieldTextStudent">
                              Seu texto personalizado
                            </label>
                            <Input
                              multiline
                              name="text"
                              required
                              id="fieldTextStudent"
                              className="form-control"
                              rows="6"
                              maxLength="499"
                            />
                          </div>
                        </Col>
                        <Col xs={12} sm={12}>
                          <div className="form-group">
                            <label htmlFor="fieldTextModerator">
                              Mensagem do moderador
                            </label>
                            <Input
                              multiline
                              name="moderationMessage"
                              id="fieldTextModerator"
                              className="form-control"
                              rows="3"
                              maxLength="199"
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <Button
                            variant="danger"
                            type="submit"
                            block
                            disabled={modalLoading}
                            onClick={() => setStatusAprove(30)}
                          >
                            {modalLoading ? (
                              <Spinner animation="border" />
                            ) : (
                              <>
                                {`Recusar `}
                                <FaTimes />
                              </>
                            )}
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            variant="info"
                            type="submit"
                            block
                            disabled={modalLoading}
                            onClick={() => setStatusAprove(20)}
                          >
                            {modalLoading ? (
                              <Spinner animation="border" />
                            ) : (
                              <>
                                {`Aprovar `}
                                <FaCheck />
                              </>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      {/* Modal Video */}
      <Modal
        className="mt-5 "
        size="lg"
        show={modalVideo}
        onHide={() => setModalVideo(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header className="bg-secondary text-light" closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">Video</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-secondary">
          <Row className="justify-content-center">
            <Col>
              <div className="embed-responsive embed-responsive-16by9 mb-4">
                <iframe
                  className="embed-responsive-item"
                  allowFullScreen
                  src={`https://www.youtube.com/embed/${videoEmbed}`}
                ></iframe>
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
