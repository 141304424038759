import React, { useEffect, useRef } from "react"
import { useField } from "@rocketseat/unform"

export function UnformInput({ name, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value"
    })
  }, [fieldName, registerField])

  return (
    <input
      ref={inputRef}
      defaultValue={defaultValue}
      {...rest}
    />
  )
}
