import { takeLatest, call, put, all } from "redux-saga/effects";
import { toast } from "react-toastify";
import api from "~/services/api";

import {
  updateProfileSuccess,
  updateProfileFailure,
  acceptRegulationSuccess,
} from "./actions";

export function* updateProfile({ payload }) {
  try {
    const { name, email, avatar_id, ...rest } = payload.data;

    const profile = Object.assign(
      { name, email, avatar_id },
      rest.oldPassword ? rest : {}
    );

    const response = yield call(api.put, "users", profile);
    toast.success("Perfil atualizado com sucesso");

    yield put(updateProfileSuccess(response.data));
  } catch (err) {
    toast.error("Erro ao atualizar perfil, confira seus dados");
    yield put(updateProfileFailure());
  }
}

export function* accept_regulation({ payload }) {
  try {
    const { template } = payload;
    const response = yield call(
      api.post,
      template === "student"
        ? `studentarea/acceptregulation`
        : `giverarea/acceptregulation`
    );
    const { status, message } = response.data;
    if (status) {
      toast.success("Regulamento aceito");
      yield put(acceptRegulationSuccess(status));
    } else {
      toast.error(`${message}`);
    }
  } catch (err) {
    toast.error("Erro ao aceitar regulamento");
  }
}

export default all([
  takeLatest("@user/UPDATE_PROFILE_REQUEST", updateProfile),
  takeLatest("@user/UPDATE_ACCEPT_REGULATION_REQUEST", accept_regulation),
]);
