import React, { useRef, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";

import api from "~/services/api";
import buildURLQuery from "~/util/buildURLQuery";

import { format, set } from "date-fns";
import pt from "date-fns/locale/pt";

import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import history from "~/services/history";

import { formatPrice } from "~/util/format";
import { Form, Input, Select } from "@rocketseat/unform";
import {
  Table,
  Container,
  Row,
  Col,
  Dropdown,
  Spinner,
  Button,
  Modal,
  Card,
  InputGroup,
  FormControl,
  Jumbotron,
} from "react-bootstrap";
import { toast } from "react-toastify";
// import { Container } from './styles';

import Switch from "react-switch";

import PaginationComponent from "react-js-pagination";

import { IoMdMenu, IoMdSearch } from "react-icons/io";
import { MdClear, MdMore, MdAddCircleOutline } from "react-icons/md";
import { FaSearch, FaRegCheckCircle } from "react-icons/fa";
import { TiDeleteOutline } from "react-icons/ti";
import { it } from "date-fns/locale";

import { CSVLink, CSVDownload, } from "react-csv";

export default function Lotes() {
  let location = useLocation();
  const tenantProfile = useSelector((state) => state.header.tenantProfile);

  const onlyClosed = useMemo(() => {
    const search = new URLSearchParams(location.search);
    const view = search.get("mode");
    return view === "all" ? true : false;
  });

  const searchRef = useRef(null);
  const [searching, setSearching] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [timeout, setTimeOut] = useState(0);

  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  const [activePage, setActivePage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [totalItens, setTotalItens] = useState(0);

  const [dataLotEntity, setDataLotEntity] = useState([]);
  const [dataLotView, setDataLotView] = useState({});
  const [modalLot, setModalLot] = useState(false);
  const [dataLotLoading, setDataLotLoading] = useState(false);
  const [activePageLot, setActivePageLot] = useState(1);
  const [itemPerPageLot, setItemPerPageLot] = useState(10);
  const [totalItensLot, setTotalItensLot] = useState(0);
  const [searchStringLot, setSearchStringLot] = useState("");

  const [printPDFHead] = useState(["RA", "Aluno", "Data", "Valor", "Campanha"]);
  const [printPDFBody, setPrintPDFBody] = useState([]);

  const [printCSVBody, setPrintCSVBody] = useState([]);
  const [entidade, setEntidade] = useState();
  const [dataLote, setDataLote] = useState();

  let valorInicial = 0;

  const data = new Date();

  // change new tenant
  useEffect(() => {
    setActivePage(1);
  }, [tenantProfile, searchString]);

  /* Load data pages */
  useEffect(() => {
    loadData();
  }, [activePage, itemPerPage, tenantProfile, searchString, onlyClosed]);

  const loadData = async () => {
    try {
      setLoading(true);

      const response = await api.get(
        `/adminarea/GetAllAccountingLots/${tenantProfile}/${activePage}/${itemPerPage}?${buildURLQuery(
          { search: searchString, unnacounted: !onlyClosed }
        )}`
      );

      const dataDonations = response.data;
      setTotalItens(dataDonations.indexSize);

      setPages(
        dataDonations.entities.map((x) => ({
          ...x,
          creationDateFormatted: x.creationDate
            ? format(new Date(x.creationDate), "dd/MM/yyyy HH:mm", {
              locale: pt,
            })
            : null,
        }))
      );
    } catch (error) {
      if (!!error.response && error.response.status !== 401) {
        toast.error("Erro ao carregar a página");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (searching) {
      searchRef.current.focus();
    }
  }, [searching]);

  useEffect(() => {
    if (Object.keys(dataLotView).length !== 0) {
      listItemsLote();
    }
  }, [searchStringLot, dataLotView, activePageLot]);

  const listItemsLote = async () => {
    const { accountingLotId, dataKey } = dataLotView;
    setDataLotLoading(true);
    try {
      const response = await api.get(
        `/adminarea/GetAccountingLotTransactions/${dataKey}/${accountingLotId}/${activePageLot}/${itemPerPageLot}?${buildURLQuery(
          { search: searchStringLot }
        )}`
      );

      const dataLotResp = response.data;
      setTotalItensLot(dataLotResp.indexSize);

      setPrintCSVBody(
        dataLotResp.entities.map((x) => (
          setEntidade(x.originStudentCampus),
          setDataLote(x.accountingDate ? format(new Date(x.accountingDate), "dd/MM/yyyy", {
            locale: pt,
          })
            : null),
          {
            0: x.originStudentRa,
            1: x.originStudentName,
            2: x.transactionDate
              ? format(new Date(x.transactionDate), "dd/MM/yyyy HH:mm", {
                locale: pt,
              })
              : null,
            3: formatPrice(x.transactionValue || 0),
            4: x.originPageCampaignName
          })),
      );

      setPrintPDFBody(
        dataLotResp.entities.map((x) => ({
          0: x.originStudentRa,
          1: x.originStudentName,
          2: x.transactionDate
            ? format(new Date(x.transactionDate), "dd/MM/yyyy HH:mm", {
              locale: pt,
            })
            : null,
          3: formatPrice(x.transactionValue || 0),
          4: x.originPageCampaignName
        })),
      );

      setDataLotEntity(
        dataLotResp.entities.map((x) => ({
          ...x,
          transactionDateFormatted: x.transactionDate
            ? format(new Date(x.transactionDate), "dd/MM/yyyy HH:mm", {
              locale: pt,
            })
            : null,
          transactionValueFormatted: formatPrice(x.transactionValue || 0),
        }))
      );
    } catch (error) {
      if (!!error.response && error.response.status !== 401) {
        toast.error("Erro ao carregar a página");
      }
    } finally {
      setDataLotLoading(false);
    }
  };

  const removeAccountInLot = async (transactionId) => {
    confirmAlert({
      title: "Confirme",
      message: `Deseja mesmo remover o item deste lote?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              const response = await api.delete(
                `/adminarea/RemoveAccountingLotTransaction/${tenantProfile}/${transactionId}`
              );
              const { status, message } = response.data;
              if (status) {
                toast.success(message);
                setDataLotEntity((prevState) =>
                  prevState.filter((x) => x.transactionId !== transactionId)
                );
              } else {
                toast.error(message);
              }
            } catch (error) {
              if (!!error.response && error.response.status !== 401) {
                toast.error("Erro ao obter dados");
              }
            }
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });
  };

  const handleFind = (e) => {
    const vl = e.target.value;

    if (timeout) clearTimeout(timeout);
    setTimeOut(
      setTimeout(() => {
        setSearchString(vl);
      }, 400)
    );
  };

  const handleClear = () => {
    setSearchString("");
    setSearching(false);
  };

  const handleSearch = () => {
    setSearching(true);
  };

  const handleViewRequest = async (data) => {
    setDataLotView(data);
    setModalLot(true);
  };

  const handleRenderizeTransfer = (status) => {
    if (status) {
      history.push("/admin/allotment?mode=all");
    } else {
      history.push("/admin/allotment");
    }
  };

  const handleFindItem = (e) => {
    const vl = e.target.value;

    if (timeout) clearTimeout(timeout);
    setTimeOut(
      setTimeout(() => {
        setSearchStringLot(vl);
      }, 400)
    );
  };

  const handleContabilizeLot = async (accountId) => {
    if (dataLotEntity.length == 0) {
      toast.info(
        "Você precisa ter pelo menos um item para contabilizar o lote"
      );
      return;
    }

    confirmAlert({
      title: "Confirme",
      message: `Deseja mesmo contabilizar este lote?`,
      buttons: [
        {
          label: "Sim",
          onClick: async () => {
            try {
              const response = await api.put(
                `adminarea/SetAccountingLotAccounted/${tenantProfile}/${accountId}`
              );

              const { status, message } = response.data;
              if (status) {
                toast.success(message);
                setModalLot(false);
                loadData();
              } else {
                toast.error(message);
              }
            } catch (error) {
              if (!!error.response && error.response.status !== 401) {
                toast.error("Erro ao obter dados");
              }
            }
          },
        },
        {
          label: "Não",
          onClick: () => { },
        },
      ],
    });
  };

  const searchFunction = (data) => { };

  const generatePDF = () => {
    const doc = new jsPDF();
    // It can parse html:
    // <table id="my-table"><!-- ... --></table>
    //doc.autoTable({ html: '#my-table' });

    // Or use javascript directly:
    doc.text('Transações por Lote Contábil', 65, 16);
    doc.autoTable({
      head: [printPDFHead],
      body: printPDFBody,
      theme: 'grid',
      startY: 25
    });
    const data = new Date();
    doc.save(data.getDate() + "-" + (data.getMonth() + 1) + "-" + data.getFullYear() + ".pdf");
  };

  const generateCSV = () => {

  };

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
        <h1 className="h2">Lotes</h1>
      </div>

      {/* Search */}
      <Row className="d-flex justify-content-center">
        <Col xs={11} sm={12} className="d-flex justify-content-between mb-2">
          <label className="switchBox mb-0">
            <span>Lotes não fechados</span>
            <Switch onChange={handleRenderizeTransfer} checked={onlyClosed} />
            <span>Todos</span>
          </label>

          {!!searching ? (
            <Form onSubmit={searchFunction}>
              <InputGroup className="">
                <FormControl
                  type="text"
                  placeholder="Procurar aluno"
                  className="form-control inputSearch"
                  onChange={handleFind}
                  ref={searchRef}
                />
                <InputGroup.Append>
                  <Button variant="outline-danger" onClick={handleClear}>
                    <MdClear />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Form>
          ) : (
              <Button
                variant="outline-info"
                onClick={handleSearch}
                title="Clique para procurar"
              >
                <IoMdSearch />
              </Button>
            )}
        </Col>
      </Row>

      {/* List */}
      <Row className="d-flex justify-content-center">
        <Col xs={11} sm={12}>
          <div className="table-responsive pt-3">
            <Table responsive striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th>Cód. do Lote</th>
                  <th>Data</th>
                  <th>Contabilizado</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={6}>
                      <div className="contentcenter">
                        <Spinner animation="border" /> Carregando
                      </div>
                    </td>
                  </tr>
                ) : pages.length === 0 ? (
                  <tr>
                    <td colSpan={6} className="text-center">
                      Nenhum registro
                    </td>
                  </tr>
                ) : (
                      pages.map((item, index) => (
                        <tr key={item.accountingLotId}>
                          {/* <td>{item}</td> */}
                          <td>{item.accountingLotCode}</td>
                          <td>{item.creationDateFormatted}</td>
                          <td>{item.accounted ? "Sim" : "Não"}</td>
                          <td className="tdControl">
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                              >
                                <IoMdMenu size={22} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => handleViewRequest(item)}
                                >
                                  <MdMore size={18} color="#17a2b8" /> Visualizar
                            </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      ))
                    )}
              </tbody>
            </Table>

            <Container hidden={loading || pages.length == 0 ? true : false}>
              <Row
                className={`justify-content-center ${
                  totalItens <= itemPerPage ? "d-none" : "d-flex"
                  }`}
              >
                <Col xs={10} sm={5} className="d-flex justify-content-center">
                  <PaginationComponent
                    itemClass="page-item"
                    linkClass="page-link"
                    innerClass="pagination mb-0"
                    activePage={activePage}
                    itemsCountPerPage={itemPerPage}
                    totalItemsCount={totalItens}
                    pageRangeDisplayed={5}
                    onChange={(e) => setActivePage(e)}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>

      {/* Modal Moderar Transferencia */}

      <Modal
        className="mt-5"
        size="lg"
        show={modalLot}
        onHide={() => setModalLot(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            Transações por lote contábil
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Row>className="justify-content-center"> */}
          <Row>
            <Col>
              <>
                <Jumbotron className="py-4 text-center">
                  <h5>
                    <b>Cód. do Lote: </b>
                    {dataLotView.accountingLotCode || ""}
                  </h5>
                  <br></br>
                  <Row>
                    <Col className="col-4 text-left" style={{ paddingTop: "10px" }}>
                      <b>Contabilizado: </b>
                      {dataLotView.accounted ? "Sim" : "Não"}
                    </Col>
                    <Col className="col-4 text-center">
                      {dataLotView.accounted ?
                        <Button variant="outline-primary" className="justify-content-left" onClick={generatePDF}>
                          Gerar PDF
                          </Button> : ""
                      }
                    </Col>
                    <Col className="col-4 text-right">
                      {dataLotView.accounted ?
                        //Gerando CSV para download
                        <CSVLink className="btn btn-outline-primary"
                          data={
                            "101330;Instituto Adventista de Ensino - Reitoria;;;;;;\n" +
                            dataLotEntity.map((it, i) => (
                              `2139090;${it.originStudentRa};10;1010201;0E;${it.transactionValue * 100};N;RA. ${it.originStudentRa} ${it.originStudentName}\n`
                            )).join("") +
                            `1136001;${entidade};10;1010201;0E;-${
                            dataLotEntity.reduce(function (acumulador, valorAtual) { return acumulador + valorAtual.transactionValue; }, valorInicial) * 100
                            };N;Liq. Conbrança ${dataLote}`
                          }
                          separator={";"}
                          enclosingCharacter={` `}
                          filename={data.getDate() + "-" + (data.getMonth() + 1) + "-" + data.getFullYear() + ".csv"}
                        >Gerar CSV</CSVLink>
                        // <Button variant="outline-primary" className="justify-content-left" onClick={generateCSV}>
                        //   {/* <CSVDownload data={csvData} target="_blank" />; */} formatPrice(x.transactionValue || 0)
                        //   Gerar CSV
                        //   </Button> 
                        : ""
                      }
                    </Col>
                  </Row>
                </Jumbotron>
                <Row>
                  <Col sm={10}>
                    <InputGroup className="mb-2">
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FaSearch />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        type="text"
                        placeholder="Procurar item"
                        className="form-control inputSearch"
                        onChange={handleFindItem}
                      />
                    </InputGroup>
                  </Col>

                  <Col sm={2} className="text-right">
                    {!dataLotView.accounted && (
                      <>
                        {/* <Button
                          variant="outline-success"
                          onClick={() =>
                            toast.info("funcionalidade não implementada")
                          }
                          title="Clique para adicionar novo item"
                          className="ml-2"
                        >
                          <MdAddCircleOutline />
                        </Button> */}
                        <Button
                          variant="outline-primary"
                          onClick={() =>
                            handleContabilizeLot(dataLotView.accountingLotId)
                          }
                          title="Clique para contabilizar o lote"
                          className="ml-2"
                        >
                          <FaRegCheckCircle />
                        </Button>
                      </>
                    )}
                  </Col>
                </Row>
                <Table striped bordered hover responsive variant="dark">
                  <thead>
                    <tr>
                      <td width="20%">RA</td>
                      <td width="20%">Aluno</td>
                      <td width="20%">Data</td>
                      <td width="20%">Valor</td>
                      <td width="20%">Campanha</td>
                      {/* <td width="20%">-</td> */}
                    </tr>
                  </thead>
                  <tbody>
                    {dataLotLoading ? (
                      <tr>
                        <td colSpan={5}>
                          <div className="contentcenter">
                            <Spinner animation="border" /> Carregando
                          </div>
                        </td>
                      </tr>
                    ) : dataLotEntity.length > 0 ? (
                      dataLotEntity.map((it, i) => (
                        <tr key={i}>
                          <td>{it.originStudentRa}</td>
                          <td>{it.originStudentName}</td>
                          <td>{it.transactionDateFormatted}</td>
                          <td>{it.transactionValueFormatted}</td>
                          <td>{it.originPageCampaignName}</td>
                          {/* <td className="tdControl">
                            <Dropdown drop="left">
                              <Dropdown.Toggle
                                variant="secondary"
                                id="dropdown-basic"
                              >
                                <IoMdMenu size={22} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {!dataLotView.accounted && (
                                  <Dropdown.Item
                                    onClick={() =>
                                      removeAccountInLot(it.transactionId)
                                    }
                                  >
                                    <TiDeleteOutline
                                      size={18}
                                      color="#e40303"
                                    />{" "}
                                    Remover item
                                  </Dropdown.Item>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </td> */}
                        </tr>
                      ))
                    ) : (
                          <tr align="center">
                            <td colSpan={5}>Nenhum item</td>
                          </tr>
                        )}
                  </tbody>
                </Table>
                {
                };
                {/* Paginação */}
                <Row>
                  <Col>
                    <Container
                      hidden={
                        dataLotLoading || dataLotEntity.length == 0
                          ? true
                          : false
                      }
                    >
                      <Row
                        className={`justify-content-center ${
                          totalItensLot <= itemPerPageLot ? "d-none" : "d-flex"
                          }`}
                      >
                        <Col
                          xs={10}
                          sm={5}
                          className="d-flex justify-content-center"
                        >
                          <PaginationComponent
                            itemClass="page-item"
                            linkClass="page-link"
                            innerClass="pagination mb-0"
                            activePage={activePageLot}
                            itemsCountPerPage={itemPerPageLot}
                            totalItemsCount={totalItensLot}
                            pageRangeDisplayed={5}
                            onChange={(e) => setActivePageLot(e)}
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
}
