
export default function validadePass (newPassword) {
    //const newPassword = "{...props}";
    let validate = true;    

    const letrasMaiusculas = /[A-Z]/;
    const letrasMinusculas = /[a-z]/;
    const numeros = /[0-9]/;
    const caracteresEspeciais = /[!|@|#|$|%|^|&|*|(|)|-|_]/;

    if(!letrasMaiusculas.test(newPassword)) {
        validate = false;
    }
    if(!letrasMinusculas.test(newPassword)){
        validate = false;
    }
    if(!numeros.test(newPassword)){
        validate = false;
    }
    if(!caracteresEspeciais.test(newPassword)){
        validate = false;
    }
    if(newPassword.length < 6){
        validate = false;
    }

    return validate;
}