import produce from "immer";

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
  exp: 0,
};
export default function auth(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@auth/SIGN_IN_REQUEST_DONOR": {
        draft.loading = true;
        break;
      }
      case "@auth/SIGN_IN_REQUEST_ADMIN": {
        draft.loading = true;
        break;
      }
      case "@auth/SIGN_IN_REQUEST_STUDENT": {
        draft.loading = true;
        break;
      }
      case "@auth/SIGN_IN_SUCCESS": {
        draft.token = action.payload.token;
        draft.user = action.payload.user;
        draft.signed = true;
        draft.loading = false;
        draft.exp = JSON.parse(atob(action.payload.token.split(".")[1])).exp;
        break;
      }
      case "@auth/SIGN_FAILURE": {
        draft.loading = false;
        break;
      }
      case "@auth/SIGN_OUT": {
        draft.token = null;
        draft.signed = false;
        draft.exp = 0;
        break;
      }
      default:
    }
  });
}
