import styled from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`

.imageStudent{
  height: 160px;
  width: 160px;
  border-radius: 50%;
  border: 3px solid rgba(255,255,255,0.3);
  background: #eee;
}
  /* max-width: 900px;
  margin: 50px auto;

  display: flex;
  flex-direction: column;

  hr {
    border: 0;
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    margin: 10px 0 20px;
  }

  .payBox {
    background: #654e7d;
    //border-radius: 21px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    label {
      margin: 10px 0;
      font-size: 16px;
      color: #fbe7e7;
    }
    input,
    select {
      background: rgba(255, 255, 255, 0.32);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #fff;
      margin: 0 0 10px;
      width: 100%;

      option {
        margin: 40px;
        background: rgba(255, 255, 255, 0.8);
        color: #000;
      }

      &::placeholder {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;

      margin: 18px 0 0;
      height: 44px;
      background: #3b9eff;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      width: 100%;
      transition: background 0.2s;

      &:hover {
        background: ${darken(0.08, "#3b9eff")};
      }
    }
  } */
`;
