import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import api from "~/services/api";
import history from "~/services/history";

import { useDispatch } from "react-redux";
import { signOut } from "~/store/modules/auth/actions";
// import { Container } from './styles';

export default function ConfirmPage() {
  //const dispatch = useDispatch();
  let location = useLocation();

  useEffect(async () => {
    const search = new URLSearchParams(location.search);
    const id = search.get("id");
    const updateCode = search.get("updateCode");

    if (!!id && !!updateCode) {
      const response = await api.post(
        `/authentication/emailconfirmation?updateCode=${updateCode}&id=${id}`,
        {}
      );
      const { message, status } = response.data;
      if (status) {
        toast.success("E-mail confirmado. Entre novamente");
        window.setTimeout(() => {
          window.location = "/logout";
        }, 3000);
      } else {
        toast.error(message);
      }
    } else {
      toast.error("Página com parâmetros inválidos");
    }
  }, []);

  return <div />;
}
